<template>
  <div class="main-element">

    <div class="container">
      <div class="page-header" id="banner" >
        <div class="row">
          <div class="col-xl-8 col-md-12 mb-3 mt-3" >
            <h2 class="font-main text">Your fun space to create or enter <span class="font-main animated-text"> NFT-Raffles</span> & open <span class="font-main animated-text">Mystery Boxes</span></h2> <!-- to win Tezos NFTs -->    
            <!-- <h6 class="text-muted"><i class="bi bi-exclamation-octagon"></i> New Update for MultiToken Raffles is live! Feedback in our <a href="https://discord.gg/JPC2WND2PR"  style="color:#7289DA;text-decoration: none;"><i class="bi bi-discord" style="color:#7289DA;"></i> Discord</a> welcomed!</h6>      -->
          </div>
          <div class="col-xl-4 col-md-12 text-center" >
            <a href="#mysterybox"><img src="Bootswatch_ Cyborg_files/images/treasure3.png" class="tilt-shaking" style="margin-top:-50px;">
            <span class="btn btn-primary font-main tilt-shaking box-shadow" style="top:0px;margin-left:50px;border:1px solid #fff"><img src="Bootswatch_ Cyborg_files/images/key.png" class="tilt-shaking" style="width:25px;">  Open Mystery NFT-Boxes</span></a>
          </div>
        </div>
      </div>
    </div>     
  </div>
</template>

<script setup>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.main-element{
  padding-top:100px;
}
.animated-text {
	background: linear-gradient(45deg, #00f0ff, #d800ff);
	background-size: 300%;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 900;
	-webkit-background-clip: text;
  background-clip: text; /* Added manually */
	-webkit-text-fill-color: transparent;
	animation: animated_text 5s ease-in-out infinite;
	-moz-animation: animated_text 5s ease-in-out infinite;
	-webkit-animation: animated_text 5s ease-in-out infinite;
}

.tilt-shaking{
  animation: tilt_shaking 0.75s infinite;
}

@keyframes animated_text {
	0% { background-position: 0px 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0px 50%; }
}

@keyframes tilt_shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(0.5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-0.5deg); }
  100% { transform: rotate(0deg); }
}
</style>
 