<template>
  <h3>Creators</h3>
  <div className="topLeadersList">
    <div className="leader" v-for="creator in this.creators.slice(0, 3)" :key="creator.key">
      <div className="containerImage">
        <img className="image" loading="lazy" :src="'https://services.tzkt.io/v1/avatars/' + creator.key" />

        <div className="crown">
          <svg id="crown1" fill="#0f74b5" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50">
            <polygon className="cls-1" points="12.7 50 87.5 50 100 0 75 25 50 0 25.6 25 0 0 12.7 50" />
          </svg>
        </div>
        <div className="leaderName"><span class="d-none d-md-block">{{ formatAddress(creator.key) }}</span></div>
      </div>
    </div>

  </div>

  <div className="playerslist">
    <div className="table">
      <div class="text-center">#</div>

      <div><i class="bi bi-person-fill" /></div>

      <div>created</div>

      <div><i class="bi bi-star-fill" /></div>

      <div>
        <i class="bi bi-ticket-detailed-fill" /> sold
      </div>

    </div>
    <div className="list">

      <div className="player" v-for="(creator, idx) in this.creators" :key="creator.key">
        <span> {{ idx + 1 }}</span>
        <div className="user">
          <img className="image" :src="'https://services.tzkt.io/v1/avatars/' + creator.key" />
          <span> {{ formatAddress(creator.key) }} </span>
        </div>
        <span> {{ creator.raffles_created }} </span>
        <span> {{ creator.raffles_featured }} </span>
        <span> {{ creator.tickets_sold }} </span>
      </div>

    </div>
  </div>
</template>
  
<script>
// import VueCountdown from '@chenfengyuan/vue-countdown'
import { mapStores } from 'pinia';
import { useRaffleStore } from '@/stores/RaffleStore';
import { formatPkhString } from "../../utils";

export default {
  name: 'RaffleCreators',
  props: {
    creators: {}
  },
  components: {
    // VueCountdown: VueCountdown,
    // SelfBuildingSquareSpinner,
  },
  computed: {
    ...mapStores(useRaffleStore),
  },
  methods: {
    formatAddress(pkh) {
      if (pkh === '') return 0
      var newWallets = JSON.parse(JSON.stringify(this.raffleStore.wallets))
      var filtered = newWallets.filter(element => element.wallet === pkh)
      if (filtered.length == 1) return filtered[0].domain
      return formatPkhString(pkh);
    }
  },
  data() {
    return {
      optional: false,
      working: false,
    }
  },
  // beforeUnmount() {
  //   // cleanup event listeners
  //   this.$refs.tzRaffleDetailModalContainer.removeEventListener('click', this.stopPropagation)
  //   document.body.removeEventListener('click', this.closeModal)
  // },
  // async mounted() {
  //   // tzRaffle #6 tokenId 7, 2000 supply
  //   // this.balance = await this.raffleStore.getRaffleBalance(7) || 2000
  //   this.stats = await this.raffleStore.getStats()
  //   // attach listeners for handling clicks outside the card, while preventing propagation
  //   // of clicks in the cards
  //   this.$refs.tzRaffleDetailModalContainer.addEventListener('click', this.stopPropagation)
  //   document.body.addEventListener('click', this.closeModal)
  // },
}
</script>
  
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Amatic+SC");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

.list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.list::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.list::-webkit-scrollbar-thumb {
  background-color: #646464;
}

.container {
  width: 445px;
  /* height: 600px; */
  /* background-color: black; */
  margin: auto;
  font-family: "Amatic SC";
  margin: auto;
  padding: 1rem;
  border-radius: 5px;
  /* box-shadow: 7px 9px 7px #00000052; */
}

.container .leader {
  display: flex;
}

.container .crown {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.container .crown svg {
  width: 3rem;
}

.container .image {
  height: 58px;
  object-fit: cover;
  width: 58px;
  background-color: black;
  border-radius: 50%;
}

.container .topLeadersList {
  display: flex;
  position: relative;
  min-height: 120px;
  padding-top: 3rem;
}

.container .topLeadersList .image {
  border-radius: 50%;
  border: 3px solid #b159ffcc;
}

.container .topLeadersList .leader:nth-child(1) {
  color: black;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99
}

.container .topLeadersList .leader:nth-child(1):after {
  content: "1";
  width: 30px;
  height: 30px;
  background: #ffc500;
  border-radius: 50%;
  position: absolute;
  right: 0;
  font-family: sans-serif;
  text-align: center;
  line-height: 30px;
  font-weight: 700;
  box-shadow: 1px 1px 4px black;
}

.container .topLeadersList .leader:nth-child(1) .image {
  width: 130px;
  height: 130px;
}

.container .topLeadersList .leader:nth-child(1) .crown {
  top: -20%;
}

.container .topLeadersList .leader:nth-child(1) .crown svg {
  fill: #ffc500;
}

.container .topLeadersList .leader:nth-child(2) {
  color: black;
  position: absolute;
  left: 15%;
  transform: translateX(-15%);
  bottom: -20%;
  /* z-index: 98 */
}

.container .topLeadersList .leader:nth-child(2):after {
  content: "2";
  width: 30px;
  height: 30px;
  background: #d4d4d4;
  border-radius: 50%;
  position: absolute;
  left: 0;
  font-family: sans-serif;
  text-align: center;
  line-height: 30px;
  font-weight: 700;
  box-shadow: 1px 1px 4px black;
}

.container .topLeadersList .leader:nth-child(2) .image {
  width: 110px;
  height: 110px;
}

.container .topLeadersList .leader:nth-child(2) .crown {
  top: -25%;
}

.container .topLeadersList .leader:nth-child(2) .crown svg {
  fill: #d4d4d4;
}

.container .topLeadersList .leader:nth-child(3) {
  color: black;
  position: absolute;
  left: 85%;
  transform: translateX(-85%);
  bottom: -20%;
}

.container .topLeadersList .leader:nth-child(3):after {
  content: "3";
  width: 30px;
  height: 30px;
  background: #ab6528;
  border-radius: 50%;
  position: absolute;
  right: 0;
  font-family: sans-serif;
  text-align: center;
  line-height: 30px;
  font-weight: 700;
  box-shadow: 1px 1px 4px black;
}

.container .topLeadersList .leader:nth-child(3) .image {
  width: 110px;
  height: 110px;
}

.container .topLeadersList .leader:nth-child(3) .crown {
  top: -25%;
}

.container .topLeadersList .leader:nth-child(3) .crown svg {
  fill: #ab6528;
}

.container .leaderName {
  position: absolute;
  text-align: center;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
}

.container .player {
  background-color: #330b7775;
  display: grid;
  grid-template-columns: 0.4fr 3fr 1fr 0.5fr 1fr;
  align-items: center;
  min-height: 42px;
  text-align: center;
  padding-right: 0.4rem;
}

.container .player .image {
  width: 28px;
  height: 28px;
  border: 1.5px solid white;
}

.container .table {
  margin-right: 0.1rem;
  display: grid;
  font-size: 14px;
  grid-template-columns: 0.4fr 3fr 1fr 0.5fr 1fr;
  text-align: left;
  margin-bottom: 0px;
}

.container .table div:nth-child(2) {
  text-align: left;
  margin-left: 5px;
}

.container .table .image {
  width: 20px;
  height: 20px;
}

.container .playerslist {
  margin-top: 6rem;
  font-size: 16px;
  overflow: hidden;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
}

.container .playerslist .player:nth-child(odd) {
  background-color: #b159ffcc;
  align-items: center;
}

.container .user {
  display: flex;
  align-items: center;
  gap: 4px;
}

.container .list {
  overflow: scroll;
  height: 18rem;
  overflow-x: hidden;
}



@media (max-width: 600px) {
  .container .playerslist {
    margin-top: 2rem;
  }

  /* .container .topLeadersList .leader:nth-child(1) .image {
    width: 100px;
    height: 100px;
  }

  .container .topLeadersList .leader:nth-child(2) .image {
    width: 80px;
    height: 80px;
  }

  .container .topLeadersList .leader:nth-child(3) .image {
    width: 80px;
    height: 80px;
  } */
}
</style>