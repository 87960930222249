import { toRaw } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import { TezosToolkit, OpKind } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import {
  CONTRACT_ADDRESS_RAFFLES,
  CONTRACT_ADDRESS_BOX,
  CONTRACT_ADDRESS_BOX_COLLAB,
  CONTRACT_ADDRESS_BOX_ART,
  TOKENS,
  formatAddress,
  CONTRACT_ADDRESS_OBJKT,
} from "@/utils";
import { toast } from "vue3-toastify";
import { useLocalStorage } from "@vueuse/core";
import "vue3-toastify/dist/index.css";

// Set the network
const network = { type: 'mainnet'}; // { type: process.env.VUE_APP_NETWORK}; // { type: 'ghostnet'}; //  MAINNET
const tzktapi = 'https://api.tzkt.io'; // process.env.VUE_APP_TZKT; // 'https://api.ghostnet.tzkt.io'; 

// initialiize the SDK
const Tezos = new TezosToolkit('https://mainnet.ecadinfra.com/'); //(process.env.VUE_APP_RPC); // ('https://rpc.ghostnet.teztnets.xyz');  https://mainnet.smartpy.io

const wallet = new BeaconWallet({
  name: "tzRaffles",
  iconUrl:
    "https://www.tzraffles.fun/Bootswatch_%20Cyborg_files/images/wallet-connect-logo.jpg",
  preferredNetwork: network.type,
}); // Takes the same arguments as the DAppClient constructor

Tezos.setWalletProvider(wallet);

// setting the color mode for beacon wallet
wallet.client.setColorMode("dark");

const getContract = async () => await Tezos.wallet.at(CONTRACT_ADDRESS_RAFFLES);
const getBoxContract = async () => await Tezos.wallet.at(CONTRACT_ADDRESS_BOX);
const getBoxCollabContract = async () => await Tezos.wallet.at(CONTRACT_ADDRESS_BOX_COLLAB);
const getBoxArtContract = async () => await Tezos.wallet.at(CONTRACT_ADDRESS_BOX_ART);


/* SignalR Code */

const signalR = require("@microsoft/signalr");

const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${tzktapi}/v1/ws`)
  .build();

connection.on("events", (msg) => {

  console.log(msg);

  if (msg.data[0].tag === "offer_accepted") {
    console.log(msg);
    var wallet2 = formatAddress(msg.data[0].payload.address);
    toast.info(
      wallet2 +
        " is opening a Mysterybox",
      {
        autoClose: 4000,
        theme: "dark",
        icon: "🔑",
      }
    );
  }
  if (msg.data[0].tag === "token_out") {
    console.log(msg);
    var wallet1 = formatAddress(msg.data[0].payload.address_0);
    toast.info(
      wallet1 +
        " received a Mysterybox NFT",
      {
        autoClose: 4000,
        theme: "dark",
        icon: "🎁",
      }
    );
  }
  if (msg.data[0].tag === "tickets_bought") {
    console.log(msg);
    var wallet = formatAddress(msg.data[0].payload.address);
    toast.info(
      wallet +
        " bought " +
        msg.data[0].payload.quantity +
        " ticket(s) to Raffle #" +
        msg.data[0].payload.raffle_id,
      {
        autoClose: 4000,
        theme: "dark",
        icon: "🎫",
      }
    );
  }
  if (msg.data[0].tag === "raffle_created") {
    console.log(msg);
    toast(
      formatAddress(msg.data[0].payload.address) +
        " created Raffle #" +
        msg.data[0].payload.nat +
        " with " +
        msg.data[0].payload.max_tickets +
        " Tickets",
      {
        autoClose: 4000,
        theme: "dark",
        icon: "🎟️",
      }
    );
  }
  this.setRaffles();
  this.setEvents();
});

// auto-reconnect
// connection.onclose(init);

const slice = 25; // Number of Raffles per Slider

export const useRaffleStore = defineStore("raffle", {
  state: () => ({
    theme: "",
    user_filter:"",
    themeContract: "",
    pkh: "",
    connected: false,
    loading: false,
    raffles: [],
    wallets: [],
    collections: [],
    tokens: [],
    events: [],
    boxes: [],
    boxes_collab: [],
    boxes_art: [],
    boxcount: '',
    boxcount_collab: '',
    boxcount_art: '',
    box_last_image_url: '',
    box_art_last_image_url: '',
    my_raffle_tickets: {},
    my_favorites: useLocalStorage("tzraffle-favorites", []),
  }),
  getters: {
    myBoxes(state) {
      let boxlist = [...state.boxes].filter(
        (element) => element.address_0 === state.pkh
      ); // this.raffles.pkh)
      return boxlist.reverse().slice(0, 8);
    },
    raffleCount(state) {
      return state.raffles.length;
    },
    rafflesFeatured(state) {
      let today = new Date().getTime() - 30 * 60 * 1000;
      let sorted = [...state.raffles].sort(function (a, b) {
        var c = new Date(a.end_date);
        var d = new Date(b.end_date);
        return c - d;
      });
      return sorted
        .filter(
          (element) =>
            new Date(element.end_date).getTime() > today &&
            element.winning_tickets.length == 0 &&
            element.status === "featured"
        )
        .slice(0, slice);
    },
    rafflesNew(state) {
      return [...state.raffles].reverse().slice(0, slice);
    },
    rafflesFree(state) {
      let sorted = [...state.raffles].sort(function (a, b) {
        var c = new Date(a.end_date);
        var d = new Date(b.end_date);
        return c - d;
      });
      let today = new Date().getTime();
      var rafflelist = sorted.filter(
        (element) =>
          new Date(element.end_date).getTime() > today &&
          element.winning_tickets.length == 0 &&
          element.max_tickets !== element.tickets_sold &&
          element.price_token === null &&
          element.ticket_price / 1000000 < 0.01 &&
          element.max_per_wallet < 4
      );
      return rafflelist.slice(0, slice);
    },
    rafflesEnding(state) {
      let today = new Date().getTime() - 30 * 60 * 1000;
      let sorted = [...state.raffles].sort(function (a, b) {
        var c = new Date(a.end_date);
        var d = new Date(b.end_date);
        return c - d;
      });
      var rafflelist = sorted.filter(
        (element) =>
          new Date(element.end_date).getTime() > today &&
          element.winning_tickets.length == 0 &&
          element.max_tickets !== element.tickets_sold
      );
      return rafflelist.slice(0, slice);
    },
    rafflesPast(state) {
      let today = new Date().getTime();
      let sorted = [...state.raffles].sort(function (a, b) {
        var c = new Date(a.end_date);
        var d = new Date(b.end_date);
        return d - c;
      });
      let rafflelist = sorted.filter(
        (element) =>
          new Date(element.end_date).getTime() < today ||
          element.winning_tickets.length > 0
      );
      return rafflelist.slice(0, slice);
    },
    async rafflesEntered(state) {
      const list = [...state.raffles].reverse();
      // fetch all my Tickets
      let list1 = await this.listAllMyRaffleTickets();
      this.tickets = list1;
      let myRaffleIds = [];
      // create array with all my entered raffle ids
      list1.forEach((element) => myRaffleIds.push(element[0]["nat"]));
      // filter raffle list with only my entered raffle ids
      return list.filter((element) => myRaffleIds.includes(element["id"]));
    },
    rafflesCreated(state) {
      let raffleslist = [...state.raffles].filter(
        (element) => element.creator === state.pkh
      ); // this.raffles.pkh)
      return raffleslist.reverse().slice(0, 25);
    },
    rafflesNewFiltered(state) {
      return (kt) =>
        [...state.raffles]
          .filter((raffles) => raffles.kt == kt)
          .reverse()
          .slice(0, slice);
    },
    rafflesTokenDetails(state) {
      return (kt) => [...state.tokens].filter((tokens) => tokens.kt == kt);
    },
    rafflesFiltered(state) {
      let auxTokenKt = 0;
      let preData;

      return (kt, tokenKt, raffleStatus) => {
        preData = [...state.raffles].filter((raffle) => {
          auxTokenKt = tokenKt === "tez" ? null : tokenKt;

          if (!tokenKt && kt) return raffle.kt == kt;
          if (!kt && tokenKt) return raffle.price_token === auxTokenKt;
          if (!kt && !tokenKt) {
            return true;
          } else {
            return raffle.kt == kt && raffle.price_token === auxTokenKt;
          }
        });

        // handle status filter if any
        let enteredRaffles = [];

        if (raffleStatus) {
          // extract raffle ids
          if (raffleStatus === "entered_raffle") {
            this.my_raffle_tickets.raffles_entered.forEach((element) =>
              enteredRaffles.push(element.id)
            );
          }

          preData = preData.filter((raffle) => {
            if (raffleStatus === "ending_raffle") {
              return (
                raffle.winning_tickets.length === 0 &&
                new Date(raffle.end_date).getTime() >
                  new Date().getTime() - 30 * 60 * 1000
              );
            }
            // if (raffleStatus === "active_raffle") {
            //   return (
            //     raffle.winning_tickets.length === 0s
            //   );
            // }

            if (raffleStatus === "ended_raffle") {
              return raffle.winning_tickets.length !== 0;
            }

            if (raffleStatus === "added_prize") {
              return (
                raffle.added_prize != 0 && raffle.winning_tickets.length == 0
              );
            }

            if (raffleStatus === "sold_out") {
              return raffle.tickets_sold === raffle.max_tickets;
            }

            if (raffleStatus === "free_raffle") {
              return (
                raffle.ticket_price == 0 && raffle.winning_tickets.length == 0
              );
            }

            if (raffleStatus === "created_raffle") {
              return raffle.creator === this.pkh;
            }

            if (raffleStatus === "entered_raffle") {
              return enteredRaffles.includes(raffle.id);
            }

            if (raffleStatus === "favorite_raffle") {
              let favs = toRaw(this.myFavorites);
              return favs.includes(parseInt(raffle.id));
            }

            if (raffleStatus === "won_raffle") {
              return this.my_raffle_tickets.raffles_won.find(
                (won) => won.id === raffle.id
              );
            }

            return true;
          });
        } else {
          preData = preData.filter((rf) => {
            return !rf.resolved_ts || rf.resolved_ts === "";
          });
        }

        // sort by time left
        if (
          raffleStatus === "ending_raffle" ||
          raffleStatus === "added_prize" ||
          raffleStatus === "free_raffle" ||
          raffleStatus === "favorite_raffle"
        ) {
          preData = preData.sort(function (a, b) {
            var c = new Date(a.end_date);
            var d = new Date(b.end_date);
            return d - c;
          });
        }

        // sort by ended date (when winner was checked)
        if (raffleStatus === "ended_raffle") {
          preData = preData.sort(function (a, b) {
            var c = new Date(a.resolved_ts);
            var d = new Date(b.resolved_ts);
            return c - d;
          });
        }

        // for entered raffles, first show the active ones sorted by time left, then the ones finished sorted by resolved time
        if (raffleStatus === "entered_raffle") {
          let unresolvedRaffles = preData.filter((r) => !r.resolved_ts);
          let resolvedRaffles = preData.filter((r) => r.resolved_ts);

          unresolvedRaffles = unresolvedRaffles.sort(function (a, b) {
            var c = new Date(a.end_date);
            var d = new Date(b.end_date);
            return d - c;
          });

          resolvedRaffles = resolvedRaffles.sort(function (a, b) {
            var c = new Date(a.resolved_ts);
            var d = new Date(b.resolved_ts);
            return c - d;
          });
          // console.log({ resolvedRaffles }, { unresolvedRaffles });
          preData = resolvedRaffles.concat(unresolvedRaffles);
        }

        return preData.reverse();
      };
    },
    myFavorites(state) {
      // TODO: for Pepe theme, filter out the raffles not using Pepe
      return state.my_favorites;
    },
  },
  actions: {
    // Subscribe Event Init

    async init() {
      // open connection
      await connection.start();
      // subscribe to head
      // await connection.invoke("SubscribeToHead");
      // subscribe to Events
      await connection.invoke("SubscribeToEvents", {
        contract: CONTRACT_ADDRESS_RAFFLES,
      });
      await connection.invoke("SubscribeToEvents", {
        contract: CONTRACT_ADDRESS_BOX,
      });
    },

    // Wallet Stuff:

    /**
     * @description requests permission to connect to the network
     */
    async connectWallet() {
      try {
        await wallet.requestPermissions({
          network: network,
        });
        this.checkWalletConnection();
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * @description Check if the wallet is connected, updates the pkh in state if connected
     */
    async checkWalletConnection() {
      try {
        const activeAccount = await wallet.client.getActiveAccount();

        if (activeAccount) {
          // If defined, the user is connected to a wallet.
          this.pkh = activeAccount.address;
          this.connected = true;
          await this.getMyRaffleTickets();
        } else {
          this.pkh = "";
          this.connected = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * @description disconnects the wallet connection to the dApp
     */
    async disconnectWallet() {
      await wallet.clearActiveAccount();
      this.checkWalletConnection();
      //state.pkh = '';
    },

    // Set States
    async setRaffles() {
      /*
        const offset = 0
        const limit = 1000
        const { data } = await axios.get(`${tzktapi}/v1/contracts/${CONTRACT_ADDRESS_RAFFLES}/bigmaps/raffles/keys?active=true&select.values=key,value&offset=${offset}&limit=${limit}`)  
                */
      const { data } = await axios.get(
        `https://trooperz.purplematter.com/tzraffles/raffles.json`
      );
      this.raffles = data.map(([id, value]) => ({ id, ...value }));

      if (this.theme === "pepe") {
        this.raffles = this.raffles.filter(
          (x) => x.price_token === this.themeContract
        );
      }

      if (this.user_filter) {
        this.raffles = this.raffles.filter(
          (x) => x.creator === this.user_filter
        );
      }
      // console.log(this.raffles);
      return;
    },
    async setEvents() {
      const offset = 0;
      const limit = 50;
      const { data } = await axios.get(
        `${tzktapi}/v1/contracts/events?contract=${CONTRACT_ADDRESS_RAFFLES}&offset=${offset}&limit=${limit}&sort.desc=level`
      );
      const trackedEvents = [
        "raffle_created",
        "tickets_bought",
        "prize_claimed",
      ];

      this.events = data.filter((e) => trackedEvents.includes(e.tag));
      // console.log("events:", this.events);
      return;
    },
    async setBoxes() {
      const offset = 0;
      const limit = 100;
      const { data } = await axios.get(
        `${tzktapi}/v1/contracts/events?contract=${CONTRACT_ADDRESS_BOX}&offset=${offset}&limit=${limit}&sort.desc=level`
      );
      const trackedEvents = [
        "token_out", "offer_accepted"
      ];
      const skippedWallets = [
        "tz1YQKSkAt9VonCMwq6MbHdqMmPA2Jd3GAh2", "tz1eUfFXTHDHJgaXX1GVuEpUpJuiPWq7eDZE"
      ];
      const data2 = await axios.get(
        `${tzktapi}/v1/contracts/${CONTRACT_ADDRESS_BOX}/storage?path=loot`
      );

      this.boxcount = data2.data.length;

      this.boxes = data.filter((e) => trackedEvents.includes(e.tag)).filter((e) => !skippedWallets.includes(e.payload.address_0));
      
      if (this.boxes[0].tag === "token_out") {
      const last_box_prize_kt = this.boxes[0].payload.address_1;
      const last_box_prize_id = this.boxes[0].payload.nat;

      this.box_last_image_url= await this.getURL(last_box_prize_kt , last_box_prize_id)

      }
      else {
        this.box_last_image_url = "";
      }
      console.log(this.box_last_image_url);
      // this.boxes = this.boxes.filter((e) => e.payload.address_0 === this.pkh);
      console.log(this.boxes);
      
      return;
    },
    async setBoxCollab() {
      const offset = 0;
      const limit = 100;
      const { data } = await axios.get(
        `${tzktapi}/v1/contracts/events?contract=${CONTRACT_ADDRESS_BOX_COLLAB}&offset=${offset}&limit=${limit}&sort.desc=level`
      );
      const trackedEvents = [
        "token_out", "offer_accepted"
      ];
      const skippedWallets = [
        "tz1YQKSkAt9VonCMwq6MbHdqMmPA2Jd3GAh2", "tz1eUfFXTHDHJgaXX1GVuEpUpJuiPWq7eDZE"
      ];
      const data2 = await axios.get(
        `${tzktapi}/v1/contracts/${CONTRACT_ADDRESS_BOX_COLLAB}/storage?path=loot`
      );

      this.boxcount_collab = data2.data.length;
    
      this.boxes_collab = data.filter((e) => trackedEvents.includes(e.tag)).filter(((e) => !skippedWallets.includes(e.payload.address_0)));

      return;
    },
    async setBoxArt() {
      const offset = 0;
      const limit = 100;
      const { data } = await axios.get(
        `${tzktapi}/v1/contracts/events?contract=${CONTRACT_ADDRESS_BOX_ART}&offset=${offset}&limit=${limit}&sort.desc=level`
      );
      const trackedEvents = [
        "token_out", "offer_accepted"
      ];
      const skippedWallets = [
        "tz1YQKSkAt9VonCMwq6MbHdqMmPA2Jd3GAh2", "tz1eUfFXTHDHJgaXX1GVuEpUpJuiPWq7eDZE"
      ];
      const data2 = await axios.get(
        `${tzktapi}/v1/contracts/${CONTRACT_ADDRESS_BOX_ART}/storage?path=loot`
      );

      this.boxcount_art = data2.data.length;

      console.log(this.boxcount_art);

      this.boxes_art = data.filter((e) => trackedEvents.includes(e.tag)).filter(((e) => !skippedWallets.includes(e.payload.address_0)));

      if (this.boxes_art[0].tag === "token_out") {
        const last_box_art_prize_kt = this.boxes_art[0].payload.address_1;
        const last_box_art_prize_id = this.boxes_art[0].payload.nat;
        this.box_art_last_image_url= await this.getURL(last_box_art_prize_kt , last_box_art_prize_id)
      }
      else {
        this.box_art_last_image_url = "";
      }

      return;
    },
    async setCollections() {
      const res = await fetch("wl-data.json");
      const data = await res.json();
      this.collections = data;
      return;
    },
    setTokens() {
      this.tokens = TOKENS;
      return;
    },
    setTheme() {
      var url = window.location.href;
      if (url.includes("pepe")) {
        this.theme = "pepe";
        this.themeTicker = "PEPE";
        this.themeContract = "KT1MZg99PxMDEENwB4Fi64xkqAVh5d1rv8Z9";
      }
      console.log(this.theme);
      return;
    },
    async setWallets() {
      const { data } = await axios.get(
        `https://trooperz.purplematter.com/tzraffles/wallet-domains.json`
      );
      this.wallets = data;
      return;
    },
    // setFavorites(favorites) {
    //   this.my_favorites = favorites;
    // },
    addFavorite(raffleId) {
      let favorites = this.my_favorites || [];
      let newFav = false;

      if (!favorites.includes(parseInt(raffleId))) {
        newFav = true;
        favorites.push(parseInt(raffleId));
      } else {
        // remove from favorites
        const index = favorites.indexOf(parseInt(raffleId));
        favorites = favorites.splice(index, 1);
      }
      localStorage.setItem("tzraffle-favorites", favorites);
      toast.info(
        `Raffle #${raffleId} ${
          newFav ? "added to" : "removed from"
        } favorites.`,
        {
          autoClose: 4000,
          theme: "dark",
          icon: "⭐",
        }
      );

      // console.log("after", { favorites });
      // this.setFavorites(favorites);
      // this.myFavorites = favorites;
    },
    // List Stuff
    getRaffle(raffleId) {
      let data = this.raffles.filter((element) => element.id == raffleId);
      return data[0] || false;
    },
    async getRaffleDetails(raffleId) {
      await this.setRaffles();
      const data = this.raffles.filter((element) => element.id == raffleId);
      console.log(data);
      return data;
    },
    async listMyCurrentRaffleTickets(raffleId) {
      if (!this.pkh || raffleId === "") return [];
      const offset = 0;
      const limit = 500;
      const { data } = await axios.get(
        `${tzktapi}/v1/contracts/${CONTRACT_ADDRESS_RAFFLES}/bigmaps/tickets/keys?active=true&select.values=value&offset=${offset}&limit=${limit}&key.address=${this.pkh}&key.nat=${raffleId}`
      );
      return data;
    },
    async listAllMyRaffleTickets() {
      console.log("userAddress:", this.pkh);
      //if (!this.pkh) return []
      const offset = 0;
      const limit = 1500;
      const { data } = await axios.get(
        `${tzktapi}/v1/contracts/${CONTRACT_ADDRESS_RAFFLES}/bigmaps/tickets/keys?active=true&select.values=key,value&offset=${offset}&limit=${limit}&key.address=${this.pkh}`
      );
      return data;
    },
    async getMyRaffleTickets() {
      if (!this.pkh) return {};
      // console.log("userAddress:", this.pkh);
      //if (!this.pkh) return []
      const { data } = await axios.get(
        `https://siwt.purplematter.com/tzraffles/wallet/${this.pkh}`
      );
      this.my_raffle_tickets = data;
      return data;
    },
    async getRaffleTickets(raffleId) {
      // console.log("raffle:", raffleId);
      const { data } = await axios.get(
        `https://siwt.purplematter.com/tzraffles/raffle/${raffleId}`
      );

      return data.entries;
    },
    async listCurrentRaffleTickets(raffleId) {
      if (raffleId === "") return [];
      //console.log('userAddress:', this.pkh)
      const offset = 0;
      const limit = 1500;
      const { data } = await axios.get(
        `${tzktapi}/v1/contracts/${CONTRACT_ADDRESS_RAFFLES}/bigmaps/tickets/keys?active=true&select.values=key,value&offset=${offset}&limit=${limit}&key.nat=${raffleId}`
      );
      return data;
    },
    async getTokenMetaData({ kt, tokenId }) {
      if (kt === "KT1TJ53kra5YhkmR6oPS4c7YvtkoQGZShNpe") {
        kt = "KT1JAZLFewgBxS2W3tPH8brNTHStwXZrpfXc";
        tokenId = 0;
      } // Fallback KT for Ghostnet Token
      const { data } = await axios.get(
        `https://api.mainnet.tzkt.io/v1/tokens?contract=${kt}&tokenId=${tokenId}`
      );
      return data;
    },
    async getTokenFlag({ kt, tokenId }) {
      const options = {
        method: "POST",
        url: "https://data.objkt.com/v3/graphql",
        headers: {
          "content-type": "application/json",
        },
        data: {
          query: `query MyQuery($kt: String, $tokenId: String) {
                  token(
                    where: {fa_contract: {_eq: $kt}, token_id: {_eq: $tokenId}}
                  ) {
                    flag
                  }
                }`,
          variables: {
            kt,
            tokenId,
          },
        },
      };

      const status = await axios
        .request(options)
        .then(function (response) {
          const res = response.data;
          return res.data?.token[0]?.flag || "none";
        })
        .catch(function (error) {
          console.error(error);
          return "none";
        });

      return status;
    },
    // Raffle Actions
    async createRaffle({
      kt,
      tokenId,
      ticketPrice,
      maxTickets,
      raffleLengthDays,
      editions,
      maxEntries,
      featured,
      priceToken,
    }) {
      try {
        if (!this.connected) await this.connectWallet();

        const contract = await getContract();
        const storage = await contract.storage();

        // console.log(storage)
        const featureFee = storage.config.feature_fee.toNumber();
        const fa2 = await Tezos.wallet.at(kt);
        const max_per_wallet = maxEntries;

        // Add Theme Token Contract if set
        if (this.themeContract) {
          priceToken = this.themeContract;
        }

        // Calculate Token Decimals
        const raffleTokenDetails = this.rafflesTokenDetails(priceToken);
        let tokenFactor = raffleTokenDetails[0]["factor"];
        if (priceToken === null) {
          tokenFactor = 1000000;
        }

        ticketPrice = ticketPrice * tokenFactor;

        const batch = await Tezos.wallet
          .batch()
          .withContractCall(
            fa2.methods.update_operators([
              {
                add_operator: {
                  owner: this.pkh,
                  operator: CONTRACT_ADDRESS_RAFFLES,
                  token_id: tokenId,
                },
              },
            ])
          )
          .withContractCall(
            contract.methodsObject.create_raffle({
              days: raffleLengthDays,
              editions,
              feature: featured,
              kt,
              max_per_wallet,
              max_tickets: maxTickets,
              ticket_price: ticketPrice,
              token_id: tokenId,
              price_token: priceToken || null,
            }),
            {
              amount: featured ? featureFee : 0,
              mutez: true,
            }
          );
        const op = await batch.send();
        await op.confirmation(1);
        return true;
      } catch (error) {
        console.log(error);
      }
    },
    // if price token undefined - it's tez
    async addToPrizePool({ raffleId, amount, priceToken }) {
      try {
        if (!this.connected) await this.connectWallet();

        if (amount <= 0) return;

        const contract = await getContract();

        // Calculate Token Decimals
        const raffleTokenDetails = this.rafflesTokenDetails(priceToken);
        let tokenFactor = raffleTokenDetails[0]["factor"];
        if (priceToken === null) {
          tokenFactor = 1000000;
        }

        amount = amount * tokenFactor;

        let batch = Tezos.wallet.batch();

        console.log(amount, raffleId, tokenFactor);

        if (priceToken) {
          const pt = await Tezos.wallet.at(priceToken);
          if (pt.methods.update_operators) {
            batch = batch.withContractCall(
              pt.methods.update_operators([
                {
                  add_operator: {
                    owner: this.pkh,
                    operator: CONTRACT_ADDRESS_RAFFLES,
                    token_id: 0,
                  },
                },
              ])
            );
          } else {
            // price token is fa1.2
            batch = batch.withContractCall(
              pt.methods.approve(CONTRACT_ADDRESS_RAFFLES, amount)
            );
          }

          batch = batch.withContractCall(
            contract.methods.add_prize_to_raffle(raffleId, amount)
          );
        } else {
          console.log(amount, raffleId, tokenFactor);

          batch = batch.withContractCall(
            contract.methods.add_prize_to_raffle(raffleId, amount),
            { amount, mutez: true }
          );
        }

        const op = await batch.send();

        await op.confirmation(1);

        return true;
      } catch (error) {
        console.log(error);
      }
    },

    async buyTickets({ quantity, raffleId, ticketPrice, priceToken }) {
      try {
        console.log({ priceToken });

        if (!this.connected) await this.connectWallet();

        const amount = quantity * ticketPrice;

        const contract = await getContract();

        if (!priceToken) {
          const op = await contract.methods
            .buy_tickets(quantity, raffleId)
            .send({ amount, mutez: true });

          await op.confirmation(1);
        } else {
          const pt = await Tezos.wallet.at(priceToken);

          let batch = Tezos.wallet.batch();

          if (pt.methods.update_operators) {
            batch = batch.withContractCall(
              pt.methods.update_operators([
                {
                  add_operator: {
                    owner: this.pkh,
                    operator: CONTRACT_ADDRESS_RAFFLES,
                    token_id: 0,
                  },
                },
              ])
            );
            // $PEPE 1% Burn
            if (priceToken === "KT1MZg99PxMDEENwB4Fi64xkqAVh5d1rv8Z9") { 
              batch = batch.withContractCall(
                pt.methods.transfer([
                  {
                    from_: this.pkh,
                    txs: [
                      {
                        to_: "tz1burnburnburnburnburnburnburjAYjjX",
                        token_id: 0,
                        amount: Math.floor(amount / 100),
                      },
                    ],
                  },
                ])
              );
            }
          } else {
            // price token is fa1.2
            batch = batch.withContractCall(
              pt.methods.approve(CONTRACT_ADDRESS_RAFFLES, amount)
            );
          }

          const op = await batch
            .withContractCall(contract.methods.buy_tickets(quantity, raffleId))
            .send();

          await op.confirmation(1);
        }

        return true;
      } catch (error) {
        console.log(error);
      }
    },

    async drawRaffle({ raffleId }) {
      try {
        if (!this.connected) await this.connectWallet();
        const contract = await getContract();
        const op = await contract.methods.draw(raffleId).send();
        await op.confirmation(1);
      } catch (error) {
        console.log(error);
      }
    },
    async checkOutcome({ raffleId }) {
      try {
        if (!this.connected) await this.connectWallet();
        const contract = await getContract();
        const op = await contract.methods.check_outcome(raffleId).send();
        await op.confirmation(1);
      } catch (error) {
        console.log(error);
      }
    },
    async claimPrize({ raffle_id, ticket_id }) {
      try {
        if (!this.connected) await this.connectWallet();
        const contract = await getContract();
        const op = await contract.methods
          .claim_prize(raffle_id, ticket_id)
          .send();
        await op.confirmation(1);
      } catch (error) {
        console.log(error);
      }
    },
    async batchBuyTickets({ quantity, raffleId, ticketPrice }) {
      try {
        //console.log({ quantity }, { raffleId }, { ticketPrice });

        if (!this.connected) await this.connectWallet();

        //const amount = quantity * ticketPrice;

        const contract = await Tezos.wallet.at(CONTRACT_ADDRESS_OBJKT);

        let microTransactions = [];

        for (let i = 0; i < quantity; i++) {
          microTransactions.push({
            kind: OpKind.TRANSACTION,
            ...contract.methods.fulfill_ask(raffleId).toTransferParams(),
            amount: ticketPrice,
            mutez: false,
          });
        }

        const batch = await Tezos.wallet.batch(microTransactions);
        const operation = await batch.send();
        console.log("Operation hash:", operation.hash);
        const result = await operation.confirmation(1);
        console.log(result);

        return true;
      } catch (error) {
        console.log(error);
      }
    },
    async batchBuyPepeTickets({ quantity, raffleId, ticketPrice, priceToken }) {
      try {
        if (!this.connected) await this.connectWallet();

        //const amount = quantity * ticketPrice;

        const contract = await Tezos.wallet.at(CONTRACT_ADDRESS_OBJKT);

        const pt = await Tezos.wallet.at(priceToken);

        let microTransactions = [];

        // update operators
        microTransactions.push({
          kind: OpKind.TRANSACTION,
          ...pt.methods
            .update_operators([
              {
                add_operator: {
                  owner: this.pkh,
                  operator: CONTRACT_ADDRESS_OBJKT,
                  token_id: 0,
                },
              },
            ])
            .toTransferParams(),
          amount: 0,
          mutez: false,
        });

        for (let i = 0; i < quantity; i++) {
          microTransactions.push({
            kind: OpKind.TRANSACTION,
            ...contract.methods.fulfill_ask(raffleId).toTransferParams(),
            amount: ticketPrice * 0,
            mutez: true,
          });
        }

        const batch = Tezos.wallet.batch(microTransactions);
        const operation = await batch.send();
        console.log("Operation hash:", operation.hash);
        const result = await operation.confirmation(1);
        console.log(result);

        return true;
      } catch (error) {
        console.log(error);
      }
    },
    async getRaffleBalance(tokenId) {
      const { data } = await axios.get(
        `https://api.tzkt.io/v1/bigmaps/149541/keys/{"nat":"${tokenId}","address":"tz1YQKSkAt9VonCMwq6MbHdqMmPA2Jd3GAh2"}`
      );
      return data.value;
    },
    async getStats() {
      const { data } = await axios.get(
        `https://trooperz.purplematter.com/tzraffles/stats.json`
      );
      // this.raffles = data.map(([id, value]) => ({ id, ...value }));
      // console.log(this.raffles);
      // console.log("stats", data);
      return data;
    },
    async getWalletCollections(wallet) {
      let res;
      let offset = 0;
      let numResults = 500;
      let pageSize = 500;
      let dataCollections;
      let returnDataCollections = [];
      let allCollections = [];

      while (numResults === pageSize) {
        try {
          res = await fetch("https://data.objkt.com/v3/graphql", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: `
                query MyQuery($pageSize: Int, $offset: Int, $wallet: String) {
                  fa(
                    where: {tokens: {holders: {holder_address: {_eq: $wallet}, quantity: {_gt: "0"}}}, name: {_is_null: false}}
                    order_by: {name: asc}
                    limit: $pageSize, offset: $offset
                  ) {
                    name
                    contract
                  }
                }`,
              variables: {
                pageSize,
                offset,
                wallet,
              },
            }),
          });

          dataCollections = await res.json();
          // console.log({ dataCollections });
          returnDataCollections = dataCollections.data?.fa || [];
          numResults = dataCollections.data?.fa.length || 0;
        } catch (err) {
          console.log("ERROR:", err);
          numResults = 0;
        }

        // store all asks
        allCollections = allCollections.concat(returnDataCollections);
        offset += pageSize;
      }

      let collData = [];
      allCollections.forEach((coll) => {
        collData.push({
          kt: coll.contract,
          name: coll.name,
        });
      });
      return collData;
    },
    async getCollectionTokens(contract) {
      let res;
      let offset = 0;
      let numResults = 500;
      let pageSize = 500;
      let dataCollections;
      let returnDataCollections = [];
      let allCollections = [];
      let wallet = this.pkh;

      while (numResults === pageSize) {
        try {
          res = await fetch("https://data.objkt.com/v3/graphql", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: `
                query MyQuery($pageSize: Int, $offset: Int, $contract: String, $wallet: String) {
                  holder(where: {address: {_eq: $wallet}}) {
                  held_tokens(
                        where: {token: {fa_contract: {_eq: $contract}}, holder_address: {_eq: $wallet }, quantity: {_gt: "0"}}
                        order_by: {token: {token_id: asc}}
                        limit: $pageSize, offset: $offset
                      ) {
                        token {
                          token_id
                          name
                        }
                      }
                    }
                  }`,
              variables: {
                pageSize,
                offset,
                contract,
                wallet,
              },
            }),
          });

          dataCollections = await res.json();
          // console.log({ dataCollections });
          returnDataCollections =
            dataCollections.data?.holder[0].held_tokens || [];
          numResults = dataCollections.data?.holder[0].held_tokens.length || 0;
        } catch (err) {
          console.log("ERROR:", err);
          numResults = 0;
        }

        // store all asks
        allCollections = allCollections.concat(returnDataCollections);
        offset += pageSize;
      }

      let collData = [];
      allCollections.forEach((coll) => {
        collData.push({
          token_id: coll.token.token_id,
          name: coll.token.name,
        });
      });

      // sort here again, because token_id is a string
      return collData.sort((a, b) => Number(a.token_id) - Number(b.token_id));
    },

      // Box Actions
      async openBoxTez({ amount }) {
        try {
          if (!this.connected) await this.connectWallet();

          const contract = await getBoxContract();
          const op = await contract.methods
            .offer_xtz()
            .send({ amount, mutez: true });
           // .send();
          await op.confirmation(1);
          return true;
        } catch (error) {
          console.log(error);
        }
      },
      async openBoxCollabTez({ amount }) {
        try {
          if (!this.connected) await this.connectWallet();

          const contract = await getBoxCollabContract();
          const op = await contract.methods
            .offer_xtz()
            .send({ amount, mutez: true });
           // .send();
          await op.confirmation(1);
          return true;
        } catch (error) {
          console.log(error);
        }
      },
      async openBoxArtTez({ amount }) {
        try {
          if (!this.connected) await this.connectWallet();

          const contract = await getBoxArtContract();
          const op = await contract.methods
            .offer_xtz()
            .send({ amount, mutez: true });
           // .send();
          await op.confirmation(1);
          return true;
        } catch (error) {
          console.log(error);
        }
      },
      async openBoxToken({amount, kt, tokenId }) {
        try {
          if (!this.connected) await this.connectWallet();

          console.log({ amount, kt, tokenId  });
  
          const contract = await getBoxContract();
          const fa2 = await Tezos.wallet.at(kt);

          console.log(contract);

  
          const batch = await Tezos.wallet
            .batch()
            .withContractCall(
              fa2.methods.update_operators([
                {
                  add_operator: {
                    owner: this.pkh,
                    operator: CONTRACT_ADDRESS_BOX,
                    token_id: tokenId,
                  },
                },
              ])
            )
            .withContractCall(
              contract.methods.offer_token(
                kt,
                tokenId
              ),
              {
                amount: amount,
                mutez: true,
              }
            );
          const op = await batch.send();
          await op.confirmation(1);
          return true;
        } catch (error) {
          console.log(error);
        }
      },
      async openBoxArtToken({amount, kt, tokenId }) {
        try {
          if (!this.connected) await this.connectWallet();

          console.log({ amount, kt, tokenId  });
  
          const contract = await getBoxArtContract();
          const fa2 = await Tezos.wallet.at(kt);

          console.log(contract);

  
          const batch = await Tezos.wallet
            .batch()
            .withContractCall(
              fa2.methods.update_operators([
                {
                  add_operator: {
                    owner: this.pkh,
                    operator: CONTRACT_ADDRESS_BOX_ART,
                    token_id: tokenId,
                  },
                },
              ])
            )
            .withContractCall(
              contract.methods.offer_token(
                kt,
                tokenId
              ),
              {
                amount: amount,
                mutez: true,
              }
            );
          const op = await batch.send();
          await op.confirmation(1);
          return true;
        } catch (error) {
          console.log(error);
        }
      },
      async addToBox({kt, tokenId }) {
        try {
          if (!this.connected) await this.connectWallet();

          console.log({ kt, tokenId  });
  
          const contract = await getBoxContract();
          const fa2 = await Tezos.wallet.at(kt);

          console.log(contract);

          const batch = await Tezos.wallet
            .batch()
            .withContractCall(
              fa2.methods.update_operators([
                {
                  add_operator: {
                    owner: this.pkh,
                    operator: CONTRACT_ADDRESS_BOX,
                    token_id: tokenId,
                  },
                },
              ])
            )
            .withContractCall(
              contract.methods.admin_add_token(
                kt,
                tokenId
              )
            );
          const op = await batch.send();
          await op.confirmation(1);
          return true;
        } catch (error) {
          console.log(error);
        }
      },
      async addToCollabBox({kt, tokenId }) {
        try {
          if (!this.connected) await this.connectWallet();
    
          console.log({ kt, tokenId  });
    
          const contract = await getBoxCollabContract();
          const fa2 = await Tezos.wallet.at(kt);
    
          console.log(contract);
    
          const batch = await Tezos.wallet
            .batch()
            .withContractCall(
              fa2.methods.update_operators([
                {
                  add_operator: {
                    owner: this.pkh,
                    operator: CONTRACT_ADDRESS_BOX_COLLAB,
                    token_id: tokenId,
                  },
                },
              ])
            )
            .withContractCall(
              contract.methods.admin_add_token(
                kt,
                tokenId
              )
            );
          const op = await batch.send();
          await op.confirmation(1);
          return true;
        } catch (error) {
          console.log(error);
        }
      },
      async addToArtBox({kt, tokenId }) {
        try {
          if (!this.connected) await this.connectWallet();
    
          console.log({ kt, tokenId  });
    
          const contract = await getBoxArtContract();
          const fa2 = await Tezos.wallet.at(kt);
    
          console.log(contract);
    
          const batch = await Tezos.wallet
            .batch()
            .withContractCall(
              fa2.methods.update_operators([
                {
                  add_operator: {
                    owner: this.pkh,
                    operator: CONTRACT_ADDRESS_BOX_ART,
                    token_id: tokenId,
                  },
                },
              ])
            )
            .withContractCall(
              contract.methods.admin_add_token(
                kt,
                tokenId
              )
            );
          const op = await batch.send();
          await op.confirmation(1);
          return true;
        } catch (error) {
          console.log(error);
        }
      },
      async getURL(kt, tokenId){
        try { 
            const meta = await this.getTokenMetaData({ kt: kt, tokenId: tokenId });
            console.log(meta);
            let shortlink = ''
            if (typeof meta[0]?.metadata?.displayUri !== "undefined") { shortlink = meta[0]?.metadata?.displayUri.substring(7) } else { shortlink = "" }
            let url = "https://nftstorage.link/ipfs/" + shortlink
            console.log(url);
            return url
        } catch(errorReason) { 
        // code on error
        }
      }, 
  },



});
