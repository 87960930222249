<template>
  <!--RaffleDetailModal-->
  <div class="modal fade" :class="{ show: show }" id="raffle" aria-labelledby="#raffle" tabindex="-1" :aria-hidden="!show"
    :aria-modal="show" :style="{ display: show ? 'block' : 'none' }">
    <div ref="raffleDetailModalContainer" class="modal-dialog">
      <div class="modal-content modal-border box-shadow">
        <div class="modal-header pb-075">
          <div class="card-body text-center text-truncate">
            <h5 class="card-title text-truncate fs-1-25">
              <i class="favorite-icon bi bi-star-fill" @click="() => { this.raffleStore.addFavorite(raffle) }"
                data-bs-toggle="tooltip" data-bs-placement="top"
                :class="{ 'bi-star-fill': myFav.includes(raffle), 'bi-star': !myFav.includes(raffle) }"
                title="Favorite Raffle" />
              <i v-if="featured === true" class="bi bi-patch-check" data-bs-toggle="tooltip" data-bs-placement="top"
                title="Featured Raffle" />
              <i v-else-if="flagged === true" class="bi bi-flag-fill text-danger" data-bs-toggle="tooltip"
                data-bs-placement="top" title="Flagged Raffle" />
              <span class="font-main-light"> Raffle #{{ raffle }}
                <span v-show="editions > 1"> - {{ editions }}x</span>
              </span>
              <br />
              <span>{{ tokenName }}</span>
              <a @click="copylink" class="pe-auto pointer" data-bs-toggle="tooltip" data-bs-placement="top"
                title="Copy Raffle Link"><i class="bi bi-link-45deg  mt-2"></i></a>
            </h5>
            <span @click="copycontract" data-bs-toggle="tooltip" data-bs-placement="top"
              title="Copy Full Contract Address" class="badge bg-light badge-max-width2 text-truncate me-1"><i
                class="bi bi-card-image pe-1"></i> {{ ktName }} </span>
            <span @click="copywallet(creator)" data-bs-toggle="tooltip" data-bs-placement="top"
              title="Show Wallet Details" class="badge bg-secondary badge-max-width2 text-truncate"><i
                class="bi bi-person-fill"></i> <a :href="'https://tzkt.io/' + `${creator}` + '/operations/'"
                target="_blank" style="text-decoration: none;
    color: white;">{{ formatAddress(creator) }}</a></span>&nbsp;
          </div>

          <button @click="$emit('close')" type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body pb-0">
          <div class="card-body text-center">
            <!-- End of Raffle Section -->
            <div v-show="raffleOver === true" class="row">
              <span class="w-100">
                <h6 v-if="raffleOver && Number(totalEntries) < editions">Raffle Extended until {{ editions }} <i class="bi bi-ticket-fill"></i> sold</h6>
                <h6 v-else class="font-main">Raffle over</h6>
                <video v-show="myWinningTickets.length !== 0" controls loop class="w-100 mb-1" autoplay muted>
                  <source src="Bootswatch_ Cyborg_files/images/youwon.mp4" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
                <span>
                  <button v-show="drawEpoch === '0' && Number(totalEntries) >= editions" @click="drawRaffle" type="button"
                    class="btn btn-primary w-100 mt-2 mb-2">Draw Raffle</button>
                  <button v-show="winningTickets.length === 0 && drawEpoch !== '0'" @click="checkOutcome" type="button"
                    class="btn btn-primary w-100 mt-2 mb-2">Check Outcome*</button>
                  <span v-show="winningTickets.length === 0 && drawEpoch !== '0'" class="small mb-4">*Takes up to 6
                    minutes before it can be checked.</span>
                  <!--<button @click="claimPrize" type="button" class="btn btn-primary w-100 mt-2 mb-2">Claim Prize(s)</button> -->
                </span>
                <div v-show="myWinningTickets.length !== 0" class="mb-4">
                  <div class="pt-2" style="max-height: 170px; overflow: auto;">
                    <span v-for="(ticketId, index) in myWinningTickets" :key="index">
                      <h6>Claim Prizes:</h6><button @click="claimPrize({ ticketId })" class="btn btn-primary m-1"><i
                          class="bi bi-ticket-detailed"></i> #{{ ticketId }}</button>
                    </span>
                  </div>
                </div>
              </span>
            </div>
            <div class="row">
              <div class="col-sm-7">
                <div class="img-wrapper img-wrapper-grid" style="">
                  <span v-if='kt === "KT1MxDwChiDwd6WBVs24g1NjERUoK622ZEFp" || kt === "KT1TR1ErEQPTdtaJ7hbvKTJSa1tsGnHGZTpf"'>
                    <v-lazy-image :src="`${prize.image}`" class="d-block" style="width:240px;image-rendering:pixelated;"/>
                  </span>
                  <span v-else>
                  <v-lazy-image v-if="thumbnailUrl !== 'https://nftstorage.link/ipfs/'" :src="`${thumbnailUrl}`"
                    class="d-block w-100" />
                  <self-building-square-spinner v-else style="" :size="25" :color="'#23c2cc'" />
                  </span>
                </div>
              </div>
              <div class="col-sm-5 text-start pe-1 pt-3">
                <span class="badge bg-light me-1 mb-2 modal-border box-shadow" v-show="addedTezPrize > 0.01"
                  style="padding:8px;"><i class="bi bi-trophy"></i> Bonus: {{
                    addedTezPrize }} {{ tokenTicker }} </span>
                <span class="badge bg-light me-1 mb-2 modal-border box-shadow" v-show="raffle === 298 || raffle === 354"
                  style="padding:8px;"><i class="bi bi-exclamation-octagon"></i>
                  Funds go to the $PEPE DAO</span>
                <span class="badge bg-light me-1 mb-2 modal-border box-shadow" v-show="raffle === 479"
                  style="padding:8px;"><i class="bi bi-exclamation-octagon"></i>
                  Funds: Buy & Burn $PEPE</span>
                <span class="badge bg-light me-1 mb-2 modal-border box-shadow" v-show="raffle === 919"
                style="padding:8px;"><i class="bi bi-exclamation-octagon"></i>
                Funds get donated!</span>
                <span class="badge bg-light me-1 mb-2 modal-border box-shadow" v-show="raffle === 303 || raffle === 304"
                  style="padding:8px;"><i class="bi bi-fire"></i> PEPE funds will get burned</span>
                <span class="badge bg-light me-1" v-show="editions > 1"><i class="bi bi-trophy-fill"></i> Prize: {{
                  editions }} NFT</span>
                <span class="badge bg-primary me-1"><i class="bi bi-ticket"></i> Ticket: <span
                    id="ticket-price-span">{{ Math.round(ticketPrice / tokenFactor * 1000)/1000 }}</span> {{ tokenTicker }}</span>
                <span v-if="floor !== 'N/A'" class="badge bg-primary me-1"><i class="bi bi-file-image"></i> Prize Floor: {{ floor }}ꜩ <span
                    v-show="editions > 1">x {{ editions }} </span> </span>
                <span class="badge bg-secondary me-1"><i class="bi bi-ticket"></i> Volume: {{ (totalEntries * ticketPrice
                  / tokenFactor).toFixed(2) }} {{ tokenTicker }} </span>
                <span class="badge bg-secondary me-1"><i class="bi bi-ticket-fill"></i> Vol. max: {{ (maxTickets *
                  ticketPrice / tokenFactor).toFixed(2) }} {{ tokenTicker }} </span>
                <div class=""><a :href="`${objktLink}` + `${kt}` + '/' + `${tokenId}`" target="_blank"><i
                      class="bi bi-box-arrow-in-up-right"></i><img src="Bootswatch_ Cyborg_files/images/objkt-white.png"
                      style="height:13px;padding-left:5px"></a>

                  &nbsp;<ShareNetwork network="twitter" :url="`https://tzraffles.fun/#raffle/${raffle}`"
                    twitter-user="tzRaffles"
                    :title="tokenName + ` - ${maxTickets} tickets x ${ticketPrice / tokenFactor} ${tokenTicker.toLowerCase()}`"
                    hashtags="tzRaffles,Tezos">
                    <span class="badge bg-primary me-1">
                      <i class="bi bi-twitter"></i> Share
                    </span>

                  </ShareNetwork>
                </div>

              </div>
            </div>

            <ul class="list-group list-group-flush text-center">
              <li class="list-group-item"></li>
              <li class="list-group-item pt-4 pb-4">
                <div class="row">
                  <div class="col-md-3 pb-2">
                    <div class="small font-main-light-italic">Tickets</div>
                    <input type="number" class="form-control font-main text-center" :class="validateMyEntries"
                      id="buy-quantity" v-model="buyTicketsAmount" min="1" :max="`${myMaxEntries}`" step="1"
                      style="height:45px;">
                  </div>
                  <div class="col-md-9 font-main-light-italic">
                    <div class="small">
                      <i class="bi bi-clock-history pe-1" />
                      <span v-if="totalEntries == maxTickets">Sold-Out</span>
                      <span v-else-if="raffleOver && Number(totalEntries) < editions">Raffle Extended</span>
                      <span v-else-if="raffleOver">Raffle Over</span>
                      <span v-else>
                        <vue-countdown v-show="!raffleOver" :time="time" :interval="100"
                          v-slot="{ days, hours, minutes, seconds }">
                          <span v-show="days > 0">{{ days }}D-</span>
                          <span v-show="hours > 0 || minutes > 0 || seconds > 0">{{ hours }}H-{{ minutes }}M-{{ seconds
                          }}S</span>
                        </vue-countdown>
                      </span>
                    </div>
                    <button @click="buyTickets" type="button" class="btn btn-primary w-100 text-center"
                      :class="{ disabled: raffleOver && Number(totalEntries) >= editions, 'btn-secondary': raffleOver && Number(totalEntries) >= editions }"
                      style="height:45px;">
                      <span v-if="working" class="text-center">
                        <self-building-square-spinner style="margin: 0 auto;" :animation-duration="6000" :size="25"
                          :color="'#23c2cc'" />
                      </span>
                      <span v-else>
                          <span v-if="ticketPrice == 0"> {{ buyTicketsAmount }} <i class="bi bi-ticket-detailed-fill"></i> FREE ENTRY</span>
                          <span v-else>
                          Buy <span id="button-quantity">{{ buyTicketsAmount }}</span> <i
                          class="bi bi-ticket-detailed-fill"></i> for <span v-if="priceToken !== 'KT1MZg99PxMDEENwB4Fi64xkqAVh5d1rv8Z9'"
                          id="button-volume">{{ (buyTicketsAmount * ticketPrice / tokenFactor) }}</span><span v-else
                          id="button-volume">{{ (buyTicketsAmount * ticketPrice * 1.01 / tokenFactor) }}</span>
                        {{ tokenTicker }}</span>
                      </span>  
                    </button>
                  </div>
                  <div class="row small text-muted text-center">
                    <div class="col">
                      Before buying tickets, make sure to read our raffles <a href="#faqModal" target="_blank"
                        class="text-decoration-none"><span>F.A.Q.</span></a>
                      <span v-if="priceToken === 'KT1MZg99PxMDEENwB4Fi64xkqAVh5d1rv8Z9'"><br><i class="bi bi-fire"></i> Includes 1% $PEPE burn fee</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="small font-main-light mt-2" v-show="id === 115"><i class="bi bi-info-circle"></i>Raffle Creator Note: Play at Old Trafford on June 6th 2023. Travel expenses will be at ManU's discretion. Whoever holds this NFT will have access to play on the field.</div>-->
              </li>
              <div class="mt-3">
                <ul class="nav nav-tabs font-main-italic" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button @click="switchTab('details')" :class="{ active: tab === 'details' }" class="nav-link"
                      aria-selected="true" role="tab">Details</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button @click="switchTab('entries')" :class="{ active: tab === 'entries' }" class="nav-link"
                      aria-selected="false" tabindex="-1" role="tab">Entries</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button @click="switchTab('winners')" :class="{ active: tab === 'winners' }" class="nav-link"
                      aria-selected="false" tabindex="-1" role="tab">Winners</button>
                  </li>
                  <li class="nav-item" role="presentation" v-show="!raffleOver">
                    <button @click="switchTab('addprize')" :class="{ active: tab === 'addprize' }" class="nav-link"
                      aria-selected="false" tabindex="-1" role="tab">Add Prize</button>
                  </li>
                </ul>
                <div id="myTabContentModal" class="tab-content text-start"
                  style="font-size:0.9em;max-height:170px;overflow: auto">

                  <div class="tab-pane fade show active" v-if="tab === 'details'" role="tabpanel">
                    <div class="tabtab">
                      <div class="">
                        <table class="table table-hover text-sm">
                          <tbody>
                            <tr class="">
                              <th scope="row"><i class="bi bi-ticket-detailed"></i> Tickets sold</th>
                              <td><span id="details-total-entries">{{ totalEntries }}</span> / <span
                                  id="details-max-entries">{{ maxTickets }}</span></td>
                            </tr>
                            <tr class="">
                              <th scope=""><i class="bi bi-ticket-detailed"></i> My tickets</th>
                              <td><span id="details-my-entries">{{ myEntries }}</span><span class="text-primary"> +<span
                                    id="details-my-entries-preview"> {{ newTickets }}</span></span> (Max {{ myMaxEntries
                                    }})</td>
                            </tr>
                            <tr>
                              <th scope=""><i class="bi bi-dice-6"></i> Chance to win current</th>
                              <td><span id="details-win-chance-current">{{ winChanceCurrent.toFixed(2) }}</span>%<span
                                  class="text-primary"> <i class="bi bi-graph-up-arrow ps-1"></i> <span
                                    id="details-win-chance-preview1">{{ winChancePreviewCurrent }}</span>%</span></td>
                            </tr>
                            <tr class="">
                              <th scope=""><i class="bi bi-dice-6-fill"></i> Chance to win if sold-out</th>
                              <td><span id="details-win-chance-soldout">{{ winChanceSoldout.toFixed(2) }}</span>%<span
                                  class="text-primary"> <i class="bi bi-graph-up-arrow ps-1"></i> <span
                                    id="details-win-chance-preview2">{{ winChancePreviewSoldout }}</span>%</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade show active" v-if="tab === 'entries'" role="tabpanel">
                    <div class="tabtab">
                      <div class="">
                        <table class="table table-hover text-sm">
                          <tbody>
                            <tr v-for="ticket in this.entries" :key="ticket">
                              <td style="min-width:175px;"><i class="bi bi-person-fill pe-1"></i><span
                                  @click="copywallet(ticket.user)"> {{ formatAddress(ticket.user) }}</span>
                              </td>

                              <td>
                                <span>{{ ticket.tickets?.length }}x</span>
                              </td>

                              <td>
                                <span class="small me-1">
                                  <span :class="{ 'icon-green': this.winningTickets.includes(ticketNr.toString()) }"
                                    class="me-2" v-for="ticketNr in ticket.tickets" :key="ticketNr"> <i
                                      class="bi bi-ticket-detailed me-1"></i>{{
                                        ticketNr }}
                                  </span>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade show active" v-if="tab === 'winners'" role="tabpanel">
                    <div class="tabtab">
                      <div class="">
                        <table class="table table-hover text-sm">
                          <tbody>
                            <tr v-for="(ticket, index) in winningEntries" :key="index">
                              <td>
                                <span class="small me-1">
                                  <i class="bi bi-ticket-detailed me-1"></i>{{ ticket.ticket }}
                                </span>
                              </td>
                              <th style="min-width:175px;"><i class="bi bi-person-fill pe-1"></i><span
                                  @click="copywallet(ticket.wallet)"> {{ formatAddress(ticket.wallet) }}</span>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade show active" v-if="tab === 'addprize'" role="tabpanel">
                    <div class="tabtab text-center">
                      <!-- Add Tez to Raffle Section -->
                      <div class="card-body text-center p-3">
                        <h6 class="font-main">Add Raffle Prize:</h6><span class="w-100">
                          <form><input type="number" class="font-main text-center me-2" id="prize-quantity"
                              v-model="addTezAmount" style="max-width:100px;">
                            <button class="btn btn-primary btn-sm" @click="addToPrizePool(addTezAmount)">Add
                              {{ addTezAmount }} {{ tokenTicker }}</button>
                          </form>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SelfBuildingSquareSpinner } from 'epic-spinners'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VLazyImage from "v-lazy-image";
import { formatPkhString } from "../utils";
import { mapStores } from 'pinia';
import { useRaffleStore } from '@/stores/RaffleStore';

export default {
  name: 'ModalsRaffleDetail',
  props: {
    show: Boolean,
    raffle: Number,
  },
  emits: ['close-modal', 'close'],
  components: {
    VueCountdown: VueCountdown,
    SelfBuildingSquareSpinner,
    "v-lazy-image": VLazyImage,
  },
  computed: {
    ...mapStores(useRaffleStore),
    // build array of winning tickets with ticket id + ticket buyer address
    winningEntries() {
      let entries = this.winningTickets
      let returnEntries = [];

      let aux
      entries.forEach((element) => {
        this.entries?.forEach((user) => {
          aux = user.tickets.find(t => t == element)
          if (aux) {
            returnEntries.push({ ticket: element, wallet: user.user })
          }
        })
      });
      return returnEntries
    },
    myEntries() {
      let entries = 0
      if (typeof this.myTickets === 'undefined') return 0
      this.entries?.forEach((user) => {
        if (user.user === this.raffleStore.pkh) {
          entries = user.tickets.length
        }
      })

      // let entries = this.myTickets.length
      return entries
    },
    newTickets() {
      if (((this.myEntries + this.buyTicketsAmount) > this.myMaxEntries) || ((Number(this.totalEntries) + this.buyTicketsAmount) > this.maxTickets)) return "Max"
      return this.buyTicketsAmount
    },
    winChancePreviewCurrent() {
      if (Number(this.totalEntries) >= this.maxTickets) return 'Max'
      if ((this.myEntries + this.buyTicketsAmount) > this.myMaxEntries) return "Max"
      if ((this.buyTicketsAmount + Number(this.totalEntries)) > this.maxTickets) return "Err"
      return Math.min(((this.buyTicketsAmount + this.myEntries) * 100 / (Number(this.totalEntries) + this.buyTicketsAmount)) * this.editions, 100).toFixed(2)
    },
    winChancePreviewSoldout() {
      if (Number(this.totalEntries) >= this.maxTickets) return 'Max'
      if ((this.myEntries + this.buyTicketsAmount) > this.myMaxEntries) return "Max"
      if ((this.buyTicketsAmount + Number(this.totalEntries)) > this.maxTickets) return "Err"
      return Math.min(((this.buyTicketsAmount + this.myEntries) * 100 / this.maxTickets) * this.editions, 100).toFixed(2)
    },
    winChanceCurrent() {
      if (this.totalEntries == 0) return 0
      return Math.min((this.myEntries * 100 / this.totalEntries) * this.editions, 100)
    },
    winChanceSoldout() {
      return Math.min((this.myEntries * 100 / this.maxTickets) * this.editions, 100)
    },
    validateMyEntries() {
      if ((this.myEntries + this.buyTicketsAmount) > this.myMaxEntries || (Number(this.totalEntries) + this.buyTicketsAmount) > this.maxTickets) return 'is-invalid';
      return 'is-valid';
    },
    myFav() {
      // console.log("my favorites", this.raffleStore.myFavorites)
      return this.raffleStore.myFavorites || []
    }
  },
  watch: {
    raffle: {
      async handler(id) {
        if (!this.show) return
        if (typeof id === 'undefined') return
        const list = await this.raffleStore.listMyCurrentRaffleTickets(id);
        this.currentUser = this.raffleStore.pkh
        this.myTickets = list.flat(1);
        this.allTickets = await this.raffleStore.listCurrentRaffleTickets(id);
        const data = await this.raffleStore.getRaffleDetails(this.raffle);
        console.log(data);
        this.drawEpoch = data[0].draw_epoch
        this.ticketPrice = (data[0].ticket_price * 1).toFixed(2)
        // if(data[0].ticket_price* 1 < 0.01){ this.ticketPrice = data[0].ticket_price* 0.000001;} 
        this.tokenName = data[0].prize.name;
        this.prize = data[0].prize;
        console.log(this.prize)
        this.kt = data[0].kt;
        this.creator = data[0].creator;
        this.editions = data[0].editions;
        this.tokenId = data[0].token_id;
        this.myMaxEntries = data[0].max_per_wallet;
        this.totalEntries = data[0].tickets_sold;
        this.maxTickets = data[0].max_tickets;
        this.winningTickets = data[0].winning_tickets;
        this.myWinningTickets = this.myTickets.filter(element => data[0].winning_tickets.includes(element))
        this.priceToken = data[0].price_token;
        if (data[0].price_token === null) { this.priceToken = '' }
        const raffleTokenDetails = this.raffleStore.rafflesTokenDetails(this.priceToken);
        this.tokenTicker = raffleTokenDetails[0]["name"];
        this.tokenFactor = raffleTokenDetails[0]["factor"];
        this.addedTezPrize = (data[0].added_prize / this.tokenFactor).toFixed(2);
        // Status Checks
        // if('featured' in data[0].status) {  this.featured = true  }   
        // if('bad' in data[0].status) {  this.flagged = true  }   
        if (data[0].status === 'featured') { this.featured = true }
        if (data[0].status === 'bad' || data[0].flag === "banned") { this.flagged = true }
        // RaffleOver Check
        this.raffleEnding = data[0].end_date
        const now = new Date()
        const raffleEnd = new Date(data[0].end_date)
        this.time = raffleEnd - now
        if (raffleEnd <= now) this.time = 0
        if ((Date.parse(data[0].end_date) < Date.parse(now) || this.totalEntries === this.maxTickets)) {
          this.raffleOver = true
        }
        // Get Collection Data
        /* if (typeof this.raffleStore.collections[this.kt] === 'undefined') { this.floor = "N/A"; this.ktName = "N/A" }
        else { this.floor = this.raffleStore.collections[this.kt].floor; this.ktName = this.raffleStore.collections[this.kt].name } */
        this.floor = "N/A"
        this.ktName = this.prize.collection
        // New IMG settings
        this.thumbnailUrl = 'https://trooperz.purplematter.com/tzraffles/assets/' + this.prize.image
        if (this.prize.image === "plenty_venft") { this.thumbnailUrl = this.prize.display_uri }
        // if (this.kt === "KT1MQL8VjVQckk5A6uBfN9Qv2YUVJstG1CyH") { this.thumbnailUrl = "https://nft-picture.playstables.io/nft/collection/001/horse/" + this.tokenId + ".jpg" }
        /* getTokenMetaData for IMG 
        const meta = await this.raffleStore.getTokenMetaData({ kt: this.kt, tokenId: this.tokenId } );
        if (typeof meta[0].metadata?.name !== "undefined"){ this.tokenName = meta[0].metadata.name } else { this.tokenName = "Undefined Metadata" }
        if (typeof meta[0].metadata?.displayUri !== "undefined"){ this.thumbnailUrl = "https://nftstorage.link/ipfs/"+meta[0].metadata.displayUri.substring(7) } else { this.thumbnailUrl = ""}
        if (this.kt === "KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS"){ this.thumbnailUrl = "https://app.tezos.domains/assets/img/logo_with_white_text.svg";} 
        if (this.kt === "KT1QvXTfbonG8NtVygLfF3Tcvkzw9YLv4XDE"){ this.thumbnailUrl = "https://nftstorage.link/ipfs/QmNy46PwGxcNsVB6gqbD1YZLTs3QPkzrLRXx729ajVdq3v";this.tokenName = "$Skull King", this.ktName = "$Skulliversary"}  
        if (this.kt === "KT18kkvmUoefkdok5mrjU6fxsm7xmumy1NEw"){ this.tokenName = "Plenty veNFT", this.ktName = "PLY Vote Escrow"}             
        */
        // Winning Animation
        if (this.myWinningTickets.length !== 0) (this.startConfetti())
      },
      immediate: true
    }
  },
  methods: {
    switchTab(tab) {
      this.tab = tab
    },
    startConfetti() {
      this.$confetti.start();
    },
    endConfetti() {
      this.$confetti.end();
    },
    // Raffle Interactions
    async buyTickets() {
      try {
        this.working = true
        await this.raffleStore.buyTickets({ quantity: this.buyTicketsAmount, raffleId: this.raffle, ticketPrice: this.ticketPrice, priceToken: this.priceToken || undefined })
      } catch (e) {
        console.log(e)
      } finally {
        // TODO: fix this, using optmistic update until we get the modal data to refresh properly
        this.totalEntries = parseInt(this.totalEntries) + parseInt(this.buyTicketsAmount) + ""
        await this.refreshModal()
        this.working = false
      }
    },
    async drawRaffle() {
      try {
        this.working = true
        this.raffleStore.drawRaffle({ raffleId: this.raffle })
      } catch (e) {
        console.log(e)
      } finally {
        await this.refreshModal()
        this.working = false
      }
    },
    async checkOutcome() {
      try {
        this.working = true
        this.raffleStore.checkOutcome({ raffleId: this.raffle })
      } catch (e) {
        console.log(e)
      } finally {
        await this.refreshModal()
        this.working = false
      }
    },
    async claimPrize(winnerTicketId) {
      try {
        this.working = true
        this.raffleStore.claimPrize({ raffle_id: this.raffle, ticket_id: Number(winnerTicketId['ticketId']) })
      } catch (e) {
        console.log(e)
      } finally {
        await this.refreshModal()
        this.working = false
      }
    },
    async addToPrizePool(addTezAmount) {
      try {
        this.working = true
        this.raffleStore.addToPrizePool({ raffleId: this.raffle, amount: addTezAmount, priceToken: this.priceToken })
      } catch (e) {
        console.log(e)
      } finally {
        await this.refreshModal()
        this.working = false
      }
    },
    // copy current raffle link function
    copylink() {
      navigator.clipboard.writeText(window.location.href);
      return false
    },
    // copy raffle wallet function
    copywallet(wallet) {
      navigator.clipboard.writeText(wallet);
      return false
    },
    // copy contract function
    copycontract() {
      navigator.clipboard.writeText(this.kt);
      return false
    },
    // return tez.domain or shorten wallets
    formatAddress(pkh) {
      if (pkh === '') return 0
      var newWallets = JSON.parse(JSON.stringify(this.raffleStore.wallets))
      var filtered = newWallets.filter(element => element.wallet === pkh)
      if (filtered.length == 1) return filtered[0].domain
      return formatPkhString(pkh);
    },
    // prevent clicking inside the card from triggering the closeModal
    stopPropagation(e) {
      e.stopPropagation()
    },
    closeModal() {
      this.$emit('close-modal')
    },
    async refreshModal() {
      this.entries = await this.raffleStore.getRaffleTickets(this.raffle)
      // const list = await this.raffleStore.listMyCurrentRaffleTickets(this.raffle);
      // this.myTickets = list.flat(1);
      // this.allTickets = await this.raffleStore.listCurrentRaffleTickets(this.raffle);
      const data = await this.raffleStore.getRaffleDetails(this.raffle);
      // this.drawEpoch = data[0].draw_epoch
      // this.totalEntries = data[0].tickets_sold;
      // console.log('tickets sold on refresh:', data[0].tickets_sold);
      // this.maxTickets = data[0].max_tickets;
      // this.winningTickets = data[0].winning_tickets;
      // this.myWinningTickets = this.myTickets.filter(element => data[0].winning_tickets.includes(element))
      // this.addedTezPrize = (data[0].added_prize / this.tokenFactor).toFixed(2);

      // RaffleOver Check
      this.raffleEnding = data[0].end_date
      const now = new Date()
      const raffleEnd = new Date(data[0].end_date)
      this.time = raffleEnd - now
      if (raffleEnd <= now) this.time = 0
      if ((Date.parse(data[0].end_date) < Date.parse(now) || this.totalEntries === this.maxTickets) && this.totalEntries > 0) {
        this.raffleOver = true
      }
      // Winning Animation
      if (this.myWinningTickets.length !== 0) (this.startConfetti())
    },
  },
  data() {
    return {
      optional: false,
      working: false,
      tab: 'details',
      kt: '',
      myTickets: [],
      ktName: '',
      drawEpoch: 0,
      creator: '',
      tokenName: '',
      tokenTicker: 'TEZ',
      tokenFactor: 0,
      tokenId: 0,
      ticketPrice: 1,
      priceToken: '',
      entriesValue: 0,
      entriesValueMax: 0,
      objktLink: "https://objkt.com/asset/",
      thumbnailUrl: '',
      buyTicketsAmount: 1,
      totalEntries: 0,
      maxEntries: 0,
      myMaxEntries: 0,
      maxTickets: 0,
      editions: 1,
      maxEntriesPercent: 0,
      raffleEnding: 0,
      allTickets: [],
      winningTickets: [],
      myWinningTickets: [],
      featured: false,
      flagged: false,
      raffleOver: false,
      addedTezPrize: 0,
      floor: 0,
      time: 0,
      prize: [],
      addTezAmount: 1,
      currentUser: '',
      entries: []
    }
  },
  beforeUnmount() {
    // cleanup event listeners
    this.$refs.raffleDetailModalContainer.removeEventListener('click', this.stopPropagation)
    document.body.removeEventListener('click', this.closeModal)
    this.$confetti.stop();
  },
  async mounted() {
    this.entries = await this.raffleStore.getRaffleTickets(this.raffle)
    // console.log("entries for raffle", this.raffle, this.entries)
    // attach listeners for handling clicks outside the card, while preventing propagation
    // of clicks in the cards
    this.$refs.raffleDetailModalContainer?.addEventListener('click', this.stopPropagation)
    document.body.addEventListener('click', this.closeModal)
  },
}
</script>

<style scoped>
.np-ht {
  height: 600px;
}

.np-header {
  padding: 30px;
  background: #eee;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.v-lazy-image {
  filter: blur(20px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}

.favorite-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.icon-green {
  color: lightgreen;
}
.hoverinho{
  color:turquoise;
}
.hoverinho:hover{
  color:gold;
}
</style>