<template>
  <div class="card card-hover box-shadow main-card mt-3 mb-3" style="text-decoration: none!Important;">
    <a :href="`#raffle/${raffle.id}`">
      <div class="card-body text-center text-truncate pb-075">
        <h5 class="card-title text-truncate font-main-light fs-1-2 mb-1 color-light-purple">
          <i class="favorite-icon bi bi-star-fill" data-bs-toggle="tooltip" data-bs-placement="top"
            @click.prevent="() => { this.raffleStore.addFavorite(raffle.id) }"
            :class="{ 'bi-star-fill': fav, 'bi-star': !fav }" title="Favorite Raffle" />
          <i v-if="raffle.status === 'featured'" class="bi bi-patch-check" data-bs-toggle="tooltip"
            data-bs-placement="top" title="Promoted Raffle"></i>
          <i v-else-if="flagged === true" class="bi bi-flag-fill text-danger" data-bs-toggle="tooltip"
            data-bs-placement="top" title="Flagged Raffle" />
          Raffle #{{ raffle.id }}
        </h5>
        <h6 class="card-title text-truncate font-main">
          <span v-show="raffle.editions > 1">{{ raffle.editions }}x </span> {{ raffle.prize.name }}
        </h6>
        <span class="badge bg-light badge-max-width text-truncate me-1"><i class="bi bi-card-image"></i> {{
          raffle.prize.collection }} </span>
        <span class="badge bg-secondary badge-max-width text-truncate"><i class="bi bi-person-fill"></i> {{
          formatAddress(raffle.creator) }}</span> 
      </div>
      <div class="img-wrapper">
        <span v-if='raffle.kt === "KT1MxDwChiDwd6WBVs24g1NjERUoK622ZEFp" || raffle.kt === "KT1TR1ErEQPTdtaJ7hbvKTJSa1tsGnHGZTpf"'>
          <v-lazy-image :src="`${raffle.prize.image}`" class="d-block" style="width:240px;image-rendering:pixelated;"/>
        <span class="badge bg-light me-1 modal-border box-shadow font-main-light" v-show="raffle.added_prize > 0"
          style="position:absolute;left: 50%;  transform: translate(-50%, -50%);padding:8px;top:115px;"><i class="bi bi-trophy"></i> Bonus Prize: {{
            addedPrize }} {{ tokenTicker }} </span>
        </span>
        <span v-else>
        <v-lazy-image v-if="thumbnailUrl !== 'https://nftstorage.link/ipfs/'" :src="`${thumbnailUrl}`"
          class="d-block" />
        <self-building-square-spinner v-else style="" :size="25" :color="'#23c2cc'" />
        <span class="badge bg-light me-1 modal-border box-shadow font-main-light" v-show="raffle.added_prize > 0"
          style="position:absolute;left: 50%;  transform: translate(-50%, -50%);padding:8px;top:115px;"><i class="bi bi-trophy"></i> Bonus Prize: {{
            addedPrize }} {{ tokenTicker }} </span>
        </span>
      </div>
      <ul class="list-group list-group-flush text-center font-main">
        <li class="list-group-item item-sold">
          <i class="bi bi-ticket-detailed-fill pe-1"
            :class="{ 'icon-green': this.raffleStore.my_raffle_tickets?.raffles_entered?.find(e => e.id === raffle.id) }"></i>
          {{ raffle.tickets_sold }}/{{ raffle.max_tickets }}  
          <span v-if="ticketPrice == 0">ENTRIES</span>
          <span v-else>SOLD</span>
        </li>
        <!-- 
        <li class="list-group-item item-floor">Floor <span class="fs-1-75"><img
              src="/Bootswatch_ Cyborg_files/images/tezos.png" class="pt-1 ps-1 pe-1 tezos-logo"></span> {{ ktFloor }}
        </li>
        -->
        <li class="list-group-item item-enter color-light-purple">
          <div class="font-main-light-italic small mb-1">
            <i class="bi bi-clock-history pe-1" />
            <span v-if="(Date.parse(raffle.end_date) < new Date()) && Number(raffle.tickets_sold) < raffle.editions">Raffle Extended</span>
            <span v-else-if="raffle.tickets_sold == raffle.max_tickets && ticketPrice == 0">Giveaway over</span>
            <span v-else-if="raffle.tickets_sold == raffle.max_tickets">Sold-Out</span>
            <span v-else-if="(Date.parse(raffle.end_date) < new Date()) && ticketPrice == 0">Giveaway over</span>
            <span v-else-if="(Date.parse(raffle.end_date) < new Date())">Raffle Over</span>
            <span v-else>
              <vue-countdown :time="getTime(raffle.end_date)" :interval="100" v-slot="{ days, hours, minutes, seconds }">
                <span v-show="days > 0">{{ days }}D:</span>
                <span v-show="hours > 0 || minutes > 0 || seconds > 0">{{ hours }}H:{{ minutes }}M:{{ seconds }}S</span>
              </vue-countdown>
            </span>
          </div>
          <a :href="'#raffle/' + `${raffle.id}`" class="card-link"><button type="button" class="btn btn-primary w-100 border-0"
              style="height: 40px;"><i class="bi bi-ticket-detailed-fill me-1"></i>
              <span v-if="ticketPrice != 0"> {{ Math.round(ticketPrice / tokenFactor * 1000)/1000 }} {{ tokenTicker }}</span>
              <span v-else>FREE</span>
              </button></a>
        </li>
      </ul>
    </a>
  </div>
</template>

<script>
import { formatPkhString } from "../utils";
import VueCountdown from '@chenfengyuan/vue-countdown'
import VLazyImage from "v-lazy-image";
import { mapStores } from 'pinia';
import { useRaffleStore } from '@/stores/RaffleStore';
import { SelfBuildingSquareSpinner } from 'epic-spinners'

export default {
  props: {
    raffle: Object,
    fav: Boolean
  },
  components: {
    VueCountdown: VueCountdown,
    SelfBuildingSquareSpinner,
    "v-lazy-image": VLazyImage,
  },
  data() {
    return {
      thumbnailUrl: '',
      tokenName: '',
      ticketPrice: '0',
      tokenTicker: 'TEZ',
      tokenFactor: 0,
      addedPrize: 0,
      flagged: false
    }
  },
  computed: {
    ...mapStores(useRaffleStore),

    ktName() {
      if (typeof this.raffleStore.collections[this.raffle.kt] === "undefined") return 'N/A'
      if (this.raffle.length == 0) return
      if (this.raffleStore.collections.length == 0) return
      if (this.raffleStore.collections[this.raffle.kt].length == 0) return
      return this.raffleStore.collections[this.raffle.kt].name
    },
    ktFloor() {
      if (typeof this.raffleStore.collections[this.raffle.kt] === "undefined") return 'N/A'
      if (this.raffle.length == 0) return
      if (this.raffleStore.collections.length == 0) return
      if (this.raffleStore.raffles == 0) return
      if (this.raffle.kt === 'KT1JAZLFewgBxS2W3tPH8brNTHStwXZrpfXc' && this.raffle.token_id == 6) return 2
      var newCollections = JSON.parse(JSON.stringify(this.raffleStore.collections))
      if ((Object.values(newCollections).filter(element => element.kt === this.raffle.kt).length == 0)) return 'N/A'
      return this.raffleStore.collections[this.raffle.kt].floor
    },
    // myFav() {
    //   return this.raffleStore.myFavorites || []
    // }
  },
  async mounted() {
    // console.log('tickets in store', this.raffleStore.my_raffle_tickets)
    /* getTokenMetaData
    const data = await this.raffleStore.getTokenMetaData({ kt: this.raffle.kt, tokenId: this.raffle.token_id } );
    if(this.raffle.kt !== "KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS") {
      let shortlink = ''
      if (typeof data[0].metadata?.displayUri !== "undefined"){ shortlink = data[0].metadata.displayUri.substring(7) } else { shortlink = "" }
      let url = "https://nftstorage.link/ipfs/"+shortlink
      if (this.raffle.kt === "KT1MQL8VjVQckk5A6uBfN9Qv2YUVJstG1CyH") { url = "https://nft-picture.playstables.io/nft/collection/001/horse/"+this.raffle.token_id+".jpg" }
      this.thumbnailUrl = url
      if (typeof data[0].metadata?.name !== "undefined"){ this.tokenName = data[0].metadata.name } else { this.tokenName = "Undefined Metadata"}
      }
      if(this.raffle.kt === "KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS"){ this.thumbnailUrl = "https://app.tezos.domains/assets/img/logo_with_white_text.svg"; this.tokenName = data[0].metadata.name;}
      if(this.raffle.kt === "KT1QvXTfbonG8NtVygLfF3Tcvkzw9YLv4XDE"){ this.thumbnailUrl = "https://nftstorage.link/ipfs/QmNy46PwGxcNsVB6gqbD1YZLTs3QPkzrLRXx729ajVdq3v";this.tokenName = "$Skull King", this.ktName = "$Skulliversary"} 
      if(this.raffle.kt === "KT18kkvmUoefkdok5mrjU6fxsm7xmumy1NEw"){ this.tokenName = "Plenty veNFT", this.ktName = "PLY Vote Escrow"} 
    */

    // New IMG settings
    this.thumbnailUrl = 'https://trooperz.purplematter.com/tzraffles/assets/' + this.raffle.prize.image
    if (this.raffle.prize.image === "plenty_venft") { this.thumbnailUrl = this.raffle.prize.display_uri }
    if (this.raffle.kt === "KT1MQL8VjVQckk5A6uBfN9Qv2YUVJstG1CyH") { this.thumbnailUrl = "https://nft-picture.playstables.io/nft/collection/001/horse/" + this.raffle.token_id + ".jpg" }
    // check for objkt flag/bans
    if (this.raffle.flag === "banned") { this.flagged = true }
    this.ticketPrice = this.raffle.ticket_price;
    this.priceToken = this.raffle.price_token;
    if (this.raffle.price_token === null) { this.priceToken = '' }
    const raffleTokenDetails = this.raffleStore.rafflesTokenDetails(this.priceToken);
    this.tokenTicker = raffleTokenDetails[0]["name"];
    this.tokenFactor = raffleTokenDetails[0]["factor"];
    this.addedPrize = (this.raffle.added_prize / this.tokenFactor).toFixed(2);
  },
  methods: {

    // return tez.domain or shorten wallets
    formatAddress(pkh) {
      if (pkh === '') return 0
      var filtered = [...this.raffleStore.wallets].filter(element => element.wallet === pkh)
      if (filtered.length == 1) return filtered[0].domain
      return formatPkhString(pkh);
    },

    getTime(date) {
      const datetime = new Date(date)
      if (datetime.getTime() - new Date().getTime() < 0) return 0
      return datetime !== 'Invalid Date' ? datetime.getTime() - new Date().getTime() : null
    },
  }
}
</script>

<style scoped>
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.np-ht {
  height: 600px;
}

.np-header {
  padding: 30px;
  background: #eee;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.v-lazy-image {
  filter: blur(20px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}

.icon-green {
  color: lightgreen;
}

.favorite-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>
