<template>
  <!--RaffleDetailModal-->
  <div class="modal fade" :class="{ show: show }" id="raffle" aria-labelledby="#raffle" tabindex="-1" :aria-hidden="!show"
    :aria-modal="show" :style="{ display: show ? 'block' : 'none' }">
    <div ref="tzRaffleDetailModalContainer" class="modal-dialog">
      <div class="modal-content modal-border box-shadow">
        <div class="modal-header pb-075">
          <div class="card-body text-center text-truncate">
            <h5 class="card-title font-main-light text-truncate fs-1-25">
              tzRaffles Leaderboards
            </h5>
          </div>

          <button @click="$emit('close')" type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body pb-0">
          <div class="card-body text-center">
            <div class="row">

              <div class="mt-0">
                <ul class="nav nav-tabs font-main-italic" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button @click="switchTab('platform')" :class="{ active: tab === 'platform' }" class="nav-link"
                      aria-selected="true" role="tab"><i class="bi bi-bar-chart-fill"></i></button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button @click="switchTab('creators')" :class="{ active: tab === 'creators' }" class="nav-link"
                      aria-selected="false" tabindex="-1" role="tab"><i class="bi bi-file-plus-fill"></i></button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button @click="switchTab('participants')" :class="{ active: tab === 'participants' }"
                      class="nav-link" aria-selected="false" tabindex="-1" role="tab"><i
                        class="bi bi-ticket-detailed-fill" /></button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button @click="switchTab('winners')" :class="{ active: tab === 'winners' }" class="nav-link"
                      aria-selected="false" tabindex="-1" role="tab"><i class="bi bi-trophy-fill"></i></button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button @click="switchTab('coins')" :class="{ active: tab === 'coins' }" class="nav-link"
                      aria-selected="false" tabindex="-1" role="tab"><i class="bi bi-coin"></i></button>
                  </li>
                </ul>
              </div>

              <div v-if="this.stats?.platform && tab === 'platform'" className="container">
                <PlatformStats :stats="this.stats?.platform" />
              </div>

              <div v-if="this.stats?.creators && tab === 'creators'" className="container">
                <RaffleCreators :creators="this.stats?.creators" />
              </div>

              <div v-if="this.stats?.participants && tab === 'participants'" className="container">
                <RaffleParticipants :participants="this.stats?.participants" />
              </div>

              <div v-if="this.stats?.platform && tab === 'winners'" className="container">
                <RaffleWinners :winners="this.stats?.winners" />
              </div>

              <div v-if="this.stats?.coins && tab === 'coins'" className="container">
                <TopCurrencies :coins="this.stats?.coins" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// import VueCountdown from '@chenfengyuan/vue-countdown'
import { mapStores } from 'pinia';
import { useRaffleStore } from '@/stores/RaffleStore';
import RaffleCreators from '@/components/stats/RaffleCreators.vue'
import RaffleWinners from '@/components/stats/RaffleWinners.vue'
import RaffleParticipants from '@/components/stats/RaffleParticipants.vue'
import TopCurrencies from '@/components/stats/TopCurrencies.vue'
import PlatformStats from '@/components/stats/PlatformStats.vue'
import { formatPkhString } from "../utils";

export default {
  name: 'StatsModal',
  props: {
    show: Boolean,
    raffle: Number,
  },
  emits: ['close-modal', 'close'],
  components: {
    RaffleCreators,
    RaffleWinners,
    RaffleParticipants,
    TopCurrencies,
    PlatformStats
  },
  computed: {
    ...mapStores(useRaffleStore),
  },
  methods: {
    switchTab(tab) {
      this.tab = tab
    },
    stopPropagation(e) {
      e.stopPropagation()
    },
    closeModal() {
      this.$emit('close-modal')
    },
    formatAddress(pkh) {
      if (pkh === '') return 0
      var newWallets = JSON.parse(JSON.stringify(this.raffleStore.wallets))
      var filtered = newWallets.filter(element => element.wallet === pkh)
      if (filtered.length == 1) return filtered[0].domain
      return formatPkhString(pkh);
    }
  },
  data() {
    return {
      tab: 'platform',
      stats: {}
    }
  },
  beforeUnmount() {
    // cleanup event listeners
    this.$refs.tzRaffleDetailModalContainer.removeEventListener('click', this.stopPropagation)
    document.body.removeEventListener('click', this.closeModal)
  },
  async mounted() {
    // tzRaffle #6 tokenId 7, 2000 supply
    // this.balance = await this.raffleStore.getRaffleBalance(7) || 2000
    this.stats = await this.raffleStore.getStats()
    // attach listeners for handling clicks outside the card, while preventing propagation
    // of clicks in the cards
    this.$refs.tzRaffleDetailModalContainer.addEventListener('click', this.stopPropagation)
    document.body.addEventListener('click', this.closeModal)
  },
}
</script>
  
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Amatic+SC");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

.list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.list::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.list::-webkit-scrollbar-thumb {
  background-color: #646464;
}

.container {
  width: 445px;
  /* height: 600px; */
  /* background-color: black; */
  margin: auto;
  font-family: "Amatic SC";
  margin: auto;
  padding: 1rem;
  border-radius: 5px;
  /* box-shadow: 7px 9px 7px #00000052; */
}

.container .leader {
  display: flex;
}

.container .crown {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.container .crown svg {
  width: 3rem;
}

.container .image {
  height: 58px;
  object-fit: cover;
  width: 58px;
  background-color: black;
  border-radius: 50%;
}

.container .topLeadersList {
  display: flex;
  position: relative;
  min-height: 120px;
  padding-top: 3rem;
}

.container .topLeadersList .image {
  border-radius: 50%;
  border: 3px solid #b159ffcc;
}

.container .topLeadersList .leader:nth-child(1) {
  color: black;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99
}

.container .topLeadersList .leader:nth-child(1):after {
  content: "1";
  width: 30px;
  height: 30px;
  background: #ffc500;
  border-radius: 50%;
  position: absolute;
  right: 0;
  font-family: sans-serif;
  text-align: center;
  line-height: 30px;
  font-weight: 700;
  box-shadow: 1px 1px 4px black;
}

.container .topLeadersList .leader:nth-child(1) .image {
  width: 130px;
  height: 130px;
}

.container .topLeadersList .leader:nth-child(1) .crown {
  top: -20%;
}

.container .topLeadersList .leader:nth-child(1) .crown svg {
  fill: #ffc500;
}

.container .topLeadersList .leader:nth-child(2) {
  color: black;
  position: absolute;
  left: 15%;
  transform: translateX(-15%);
  bottom: -20%;
  /* z-index: 98 */
}

.container .topLeadersList .leader:nth-child(2):after {
  content: "2";
  width: 30px;
  height: 30px;
  background: #d4d4d4;
  border-radius: 50%;
  position: absolute;
  left: 0;
  font-family: sans-serif;
  text-align: center;
  line-height: 30px;
  font-weight: 700;
  box-shadow: 1px 1px 4px black;
}

.container .topLeadersList .leader:nth-child(2) .image {
  width: 110px;
  height: 110px;
}

.container .topLeadersList .leader:nth-child(2) .crown {
  top: -25%;
}

.container .topLeadersList .leader:nth-child(2) .crown svg {
  fill: #d4d4d4;
}

.container .topLeadersList .leader:nth-child(3) {
  color: black;
  position: absolute;
  left: 85%;
  transform: translateX(-85%);
  bottom: -20%;
}

.container .topLeadersList .leader:nth-child(3):after {
  content: "3";
  width: 30px;
  height: 30px;
  background: #ab6528;
  border-radius: 50%;
  position: absolute;
  right: 0;
  font-family: sans-serif;
  text-align: center;
  line-height: 30px;
  font-weight: 700;
  box-shadow: 1px 1px 4px black;
}

.container .topLeadersList .leader:nth-child(3) .image {
  width: 110px;
  height: 110px;
}

.container .topLeadersList .leader:nth-child(3) .crown {
  top: -25%;
}

.container .topLeadersList .leader:nth-child(3) .crown svg {
  fill: #ab6528;
}

.container .leaderName {
  position: absolute;
  text-align: center;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  font-size: 22px;
}

.container .player {
  background-color: #330b7775;
  display: grid;
  grid-template-columns: 0.4fr 3fr 1fr 0.5fr 1fr;
  align-items: center;
  min-height: 42px;
  text-align: center;
  padding-right: 0.4rem;
}

.container .player .image {
  width: 28px;
  height: 28px;
  border: 1.5px solid white;
}

.container .table {
  margin-right: 0.1rem;
  display: grid;
  font-size: 14px;
  grid-template-columns: 0.4fr 3fr 1fr 0.5fr 1fr;
  text-align: left;
  margin-bottom: 0px;
}

.container .table div:nth-child(2) {
  text-align: left;
  margin-left: 5px;
}

.container .table .image {
  width: 20px;
  height: 20px;
}

.container .playerslist {
  margin-top: 6rem;
  font-size: 16px;
  overflow: hidden;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
}

.container .playerslist .player:nth-child(odd) {
  background-color: #b159ffcc;
  align-items: center;
}

.container .user {
  display: flex;
  align-items: center;
  gap: 4px;
}

.container .list {
  overflow: scroll;
  height: 18rem;
  overflow-x: hidden;
}
</style>