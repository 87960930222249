<template>
  <div class="font-main-light">
    <div class="list-group list-group-flush border-bottom scrollarea">
      <h6 class="font-main-light-italic text pt-3 ps-4 pb-1"><i class="bi bi-activity"></i> Latest Activity:</h6>
      <div v-if="store.raffles.length > 0 && latestEvents.length > 0" style="">
        <div v-for="e in latestEvents" :key="e.id" class="font-main-light">
          <span v-if="e.tag === 'raffle_created'">
            <a :href="(store.theme === 'pepe' && !store.getRaffle(e.payload.nat) ? 'https://tzraffles.fun/#raffle/' : '/#raffle/') + `${e.payload.nat}`"
              class="list-group-item list-group-item-action py-3 lh-sm" style="border-bottom: 0px;">
              <div class="col-10 mb-1 small font-main-light-italic mt-1">{{ formatAddress(e.payload.address) }}</div>
              <div class="d-flex w-100 align-items-center justify-content-between">
                <strong class="mb-1 font-main">📢 CREATED </strong>
                <small class="font-main-light-italic small" style="font-size:12px !important;color:#ccc"><i
                    class="bi bi-clock-history"></i> {{ timeDifference(e.timestamp) }}</small>
              </div>

              <div class="col-10 mb-1 small font-main-light">
                Raffle #{{ e.payload.nat }}&nbsp;
                <i v-if="(store.theme === 'pepe' && !store.getRaffle(e.payload.nat))"
                  class="bi bi-box-arrow-in-up-right"></i>
              </div>
            </a>
          </span>
          <span v-else-if="e.tag === 'tickets_bought'">
            <a :href="(store.theme === 'pepe' && !store.getRaffle(e.payload.raffle_id) ? 'https://tzraffles.fun/#raffle/' : '/#raffle/') + `${e.payload.raffle_id}`"
              class="list-group-item list-group-item-action py-3 lh-sm" style="border-bottom: 0px;">
              <div class="col-10 mb-1 small font-main-light-italic mt-1">{{ formatAddress(e.payload.address) }}</div>
              <div class="d-flex w-100 align-items-center justify-content-between">
                <strong class="mb-1 font-main"> 🛒 BOUGHT {{ e.payload.quantity }} 🎫 </strong>
                <small class="font-main-light-italic small" style="font-size:12px !important;color:#ccc"><i
                    class="bi bi-clock-history"></i> {{ timeDifference(e.timestamp) }}</small>
              </div>
              <div class="col-10 mb-1 small font-main-light">
                Raffle #{{ e.payload.raffle_id }}&nbsp;
                <i v-if="(store.theme === 'pepe' && !store.getRaffle(e.payload.raffle_id))"
                  class="bi bi-box-arrow-in-up-right"></i>
              </div>
              <div class="col-10 mb-1 "></div>
            </a>
          </span>
          <span v-else-if="e.tag === 'prize_claimed'">
            <a :href="(store.theme === 'pepe' && !store.getRaffle(e.payload.raffle_id) ? 'https://tzraffles.fun/#raffle/' : '/#raffle/') + `${e.payload.raffle_id}`"
              class="list-group-item list-group-item-action py-3 lh-sm" style="border-bottom: 0px;">
              <div class="col-10 mb-1 small font-main-light-italic mt-1">{{ formatAddress(e.payload.address) }}</div>
              <div class="d-flex w-100 align-items-center justify-content-between">
                <strong class="mb-1 font-main"> 🏆 CLAIMED PRIZE </strong>
                <small class="font-main-light-italic small" style="font-size:12px !important;color:#ccc"><i
                    class="bi bi-clock-history"></i> {{ timeDifference(e.timestamp) }}</small>
              </div>
              <div class="col-10 mb-1 small font-main-light">
                Raffle #{{ e.payload.raffle_id }}&nbsp;
                <i v-if="(store.theme === 'pepe' && !store.getRaffle(e.payload.raffle_id))"
                  class="bi bi-box-arrow-in-up-right"></i>
              </div>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRaffleStore } from '@/stores/RaffleStore';
import { computed } from 'vue'
import { formatPkhString } from "@/utils";

const store = useRaffleStore()

store.setEvents()

setInterval(async function () {
  // console.log('refreshing events', Date.now())
  store.setEvents()
}, 15000);



const latestEvents = computed(() => store.events.slice(0, 30))

function timeDifference(previous) {

  var current = new Date();
  previous = new Date(previous)

  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + 's ago';
  }

  else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + 'm ago';
  }

  else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + 'h ago';
  }

  else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + 'D ago';
  }

  else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + 'M ago';
  }

  else {
    return Math.round(elapsed / msPerYear) + 'Y ago';
  }
}

// return tez.domain or shorten wallets
function formatAddress(pkh) {
  if (pkh === '') return 0
  var newWallets = JSON.parse(JSON.stringify(store.wallets))
  var filtered = newWallets.filter(element => element.wallet === pkh)
  if (filtered.length == 1) return filtered[0].domain
  return formatPkhString(pkh);
}
</script>