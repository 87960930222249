<template>
  <button v-if="raffleStore.pkh" @click="profile" class="is-connected btn btn-primary my-2 my-sm-0 box-shadow">
    {{ formatAddress(raffleStore.pkh) }} <i class="bi bi-caret-down-fill"></i>
  </button>
  <button v-else @click="connect" class="btn btn-primary my-2 my-sm-0 box-shadow"><i class="bi bi-credit-card"></i>
    Connect Wallet</button>
  <div class="modal fade" :class="{ show: show }" :aria-hidden="!show" :aria-modal="show"
    :style="{ display: show ? 'block' : 'none' }">
    <div ref="userModalContainer" class="modal-dialog" id="user-modal-container">
      <div class="modal-content font-main modal-border box-shadow">
        <div class="modal-header text-center">
          <button v-if="raffleStore.pkh" @click="disconnect"
            class="is-connected btn btn-primary my-2 my-sm-0 box-shadow"><i class="bi bi-link"></i> Disconnect Wallet</button>
          <a :href="'?'+`${raffleStore.pkh}`"><button class="btn btn-primary ms-1 my-2 my-sm-0 box-shadow"><i class="bi bi-shop"></i> Show my Shop</button></a>
          <button @click="close()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-start">
          <h6 class="font-main">Last Raffles Won:</h6>
          <table v-if="activity.raffles_won?.length" class="table table-hover text-sm">
            <tbody>
              <tr v-for="ticket in activity.raffles_won" :key="ticket.id"
                v-tooltip="{ content: `${this.raffleStore.getRaffle(ticket.id).prize.name }`, placement: 'bottom' }">
                <th scope="row">
                  <a :href="'#raffle/' + `${ticket.id}`" class="small">
                    <i class="bi bi-postage-heart-fill"></i>
                    #{{ ticket.id }} {{ this.raffleStore.getRaffle(ticket.id).prize?.name.slice(0, 25) }}
                  </a>
                </th>
                <td class="small">
                  <span class="me-2" v-for="winningTicket in ticket.winning_tickets" :key="winningTicket.ticket_id">
                    <i :class="{ 'icon-green': winningTicket.claimed, 'icon-red': !winningTicket.claimed }"
                      class="bi bi-ticket-detailed me-1"
                      v-tooltip="{ content: winningTicket.claimed ? 'Prize claimed.' : 'Unclaimed prize.', container: '.modal-content' }"></i>
                    {{ winningTicket.ticket_id }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <span v-if="!activity.raffles_won?.length">You haven't won any raffles yet<br />&nbsp;</span>

          <h6 class="font-main">Active Raffles Entered:</h6>
          <table v-if="activity.raffles_entered?.length" class="table table-hover text-sm">
            <tbody>
              <!--  v-tooltip="{ content: `${this.raffleStore.getRaffle(raffle.id).prize.name}`, placement: 'bottom' }" -->
              <tr v-for="raffle in activity.raffles_entered" :key="raffle.id">

                <th scope="row">
                  <a :href="'#raffle/' + `${raffle.id}`" class="small">
                    <i class="bi bi-postage-heart-fill"></i>
                    #{{ raffle.id }} {{ this.raffleStore.getRaffle(raffle.id).prize.name.slice(0, 25) }}
                  </a>
                </th>
                <td class="small">
                  {{ raffle.tickets }} Tickets
                  <span v-if="!raffle.raffle_over">&nbsp;
                    <i class="bi bi-hourglass-split icon-green"
                      v-tooltip="{ content: 'Ongoing...', container: '.modal-content' }"></i>
                  </span>
                  <span v-if="raffle.raffle_over && raffle.waiting_draw">&nbsp;
                    <i class="bi bi-check2 icon-yellow"
                      v-tooltip="{ content: 'Raffle ended. Waiting for Draw.', container: '.modal-content' }"></i>
                  </span>
                  <span v-if="raffle.raffle_over && raffle.waiting_reveal">&nbsp;
                    <i class="bi bi-check2-all icon-red"
                      v-tooltip="{ content: 'Raffle ended. Check Outcome.', container: '.modal-content' }"></i>
                  </span>
                </td>

              </tr>
            </tbody>
          </table>
          <span v-if="!activity.raffles_entered?.length">No active entered raffles<br />&nbsp;</span>

          <h6 class="font-main">Active Raffles Created:</h6>
          <table v-if="activity.raffles_created?.length" class="table table-hover text-sm">
            <tbody>
              <!-- v-tooltip="{ content: `${this.raffleStore.getRaffle(raffle.id).prize.name}`, placement: 'bottom' }" -->
              <tr v-for="raffle in activity.raffles_created" :key="raffle.id">
                <th scope="row">
                  <a :href="'#raffle/' + `${raffle.id}`" class="small">
                    <i class="bi bi-postage-heart-fill"></i>
                    #{{ raffle.id }} {{ this.raffleStore.getRaffle(raffle.id).prize.name.slice(0, 25) }}
                  </a>
                </th>
                <td class="small">
                  {{ raffle.tickets_sold }}/{{ raffle.max_tickets }} Tickets
                  <span v-if="!raffle.raffle_over">&nbsp;
                    <i class="bi bi-hourglass-split icon-green"
                      v-tooltip="{ content: 'Ongoing...', container: '.modal-content' }"></i>
                  </span>
                  <span v-if="raffle.raffle_over && raffle.waiting_draw">&nbsp;
                    <i class="bi bi-check2 icon-yellow"
                      v-tooltip="{ content: 'Raffle ended. Waiting for Draw.', container: '.modal-content' }"></i>
                  </span>
                  <span v-if="raffle.raffle_over && raffle.waiting_reveal">&nbsp;
                    <i class="bi bi-check2-all icon-red"
                      v-tooltip="{ content: 'Raffle ended. Check Outcome.', container: '.modal-content' }"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <span v-if="!activity.raffles_created?.length">No active created raffles<br />&nbsp;</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPkhString } from "../utils";
import { mapStores } from 'pinia'
import { useRaffleStore } from '@/stores/RaffleStore'

export default {
  name: " SyncButtonElement",
  data() {
    return {
      show: false,
      activity: {}
    }
  },
  computed: {
    ...mapStores(useRaffleStore),
  },
  setup() {

  },
  methods: {
    connect() {
      this.raffleStore.connectWallet();
    },
    disconnect() {
      this.raffleStore.disconnectWallet();
      this.close();
    },
    async profile() {
      const store = useRaffleStore()

      this.raffleStore.user_filter = "";
      this.raffleStore.setRaffles();

      let list = await store.getMyRaffleTickets()
      // keep only the ones in store for pepe theme (already fiters there)
      if (this.raffleStore.theme === 'pepe') {
        list.raffles_won = list.raffles_won.filter(
          (x) => {
            return this.raffleStore.raffles.find(item => item.id == x.id)
          }
        );

        list.raffles_entered = list.raffles_entered.filter(
          (x) => {
            return this.raffleStore.raffles.find(item => item.id == x.id)
          }
        );

        list.raffles_created = list.raffles_created.filter(
          (x) => {
            return this.raffleStore.raffles.find(item => item.id == x.id)
          }
        );
      }

      // return only won raffles with unclaimed prizes, if less than 5 fill with recent claimed wins to show at least 5
      let unclaimedTickets = []
      unclaimedTickets = list.raffles_won?.filter(
        (x) => {
          return x.winning_tickets.find(t => !t.claimed)
        }
      ) || [];

      if (unclaimedTickets.length < 5) {
        list.raffles_won = unclaimedTickets.concat(list.raffles_won?.filter(
          (x) => {
            return x.winning_tickets.find(t => t.claimed)
          }
        ) || []).slice(0, 5)
      } else {
        list.raffles_won = unclaimedTickets
      }

      // remove resolved raffles from entered and created raffles
      list.raffles_entered = list.raffles_entered?.filter(
        (x) => {
          return !x.raffle_over || x.waiting_draw || x.waiting_reveal
        }
      );

      list.raffles_created = list.raffles_created?.filter(
        (x) => {
          return !x.raffle_over || x.waiting_draw || x.waiting_reveal
        }
      );

      // TODO: return only raffles won with unclaimed tickets
      // TODO: add new tab / status filter for Won raffles

      // console.log(list)
      this.activity = list
      this.show = true;
    },
    close() {
      this.show = false;
    },
    formatAddress(pkh) {
      return formatPkhString(pkh);
    },
    // prevent clicking inside the card from triggering the closeModal
    stopPropagation(e) {
      e.stopPropagation()
    },
    closeModal() {
      this.show = false
    }
  },
  mounted() {
    // attach listeners for handling clicks outside the card, while preventing propagation
    // of clicks in the cards
    this.$refs.userModalContainer.addEventListener('click', this.stopPropagation)
    document.body.addEventListener('click', this.closeModal)
  },
  beforeUnmount() {
    // cleanup event listeners
    this.$refs.userlModalContainer.removeEventListener('click', this.stopPropagation)
    document.body.removeEventListener('click', this.closeModal)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon-green {
  color: lightgreen;
}

.icon-red {
  color: red;
}

.icon-yellow {
  color: yellow;
}
</style>