<template>
  <h3>Top Currencies</h3>

  <div className="playerslist">
    <div className="table">
      <div class="text-center">#</div>

      <div><i class="bi bi-coin" /></div>

      <div>created</div>

      <div><i class="bi bi-star-fill" /></div>

      <div>
        <i class="bi bi-ticket-detailed-fill" /> sold
      </div>

    </div>
    <div className="list">

      <div className="player" v-for="(coin, idx) in this.coins" :key="coin.kt">
        <span> {{ idx + 1 }}</span>
        <div className="user" @click="() => { this.selectedSymbol = coin.symbol; this.selectedCoin = coin.kt }">
          <img v-if="coin.kt !== 'tezos' && coin.kt !== 'KT1ErKVqEhG9jxXgUG2KGLW3bNM7zXHX8SDF'" className="image"
            :src="'https://services.tzkt.io/v1/avatars/' + coin.kt" />
          <img v-if="coin.kt === 'tezos'" className="image" src="tezos.png" />
          <img v-if="coin.kt === 'KT1ErKVqEhG9jxXgUG2KGLW3bNM7zXHX8SDF'" className="image" src="uno.png" />
          <span> {{ coin.symbol }} </span>
        </div>
        <span> {{ coin.raffles_total }}&nbsp;</span>
        <span> {{ coin.raffles_featured }}&nbsp;</span>
        <span> {{ coin.tickets_sold }}&nbsp;</span>
      </div>
    </div>
    <br />
    <h5 class="font-main-light">Top {{ selectedSymbol }} Degens</h5>
    <div className="list-short">
      <div className="spender" v-for="(spender, idx) in spendersCoin" :key="spender.wallet">
        <span> {{ idx + 1 }}</span>
        <div className="user">
          <img className="image" :src="'https://services.tzkt.io/v1/avatars/' + spender.wallet" />
          <span> {{ formatAddress(spender.wallet) }} </span>
        </div>
        <span v-if="selectedSymbol !== '$UP' && selectedSymbol !== '$ELXR'" style="text-align:right"> {{
          Number(spender.display_spent_total.split(" ")[0]).toLocaleString() }} {{
    spender.display_spent_total.split(" ")[1] }} </span>
        <span v-else style="text-align:right"> {{ spender.display_spent_total }} </span>
      </div>
    </div>
  </div>
</template>
  
<script>
// import VueCountdown from '@chenfengyuan/vue-countdown'
import { mapStores } from 'pinia';
import { useRaffleStore } from '@/stores/RaffleStore';
import { formatPkhString } from "../../utils";

export default {
  name: 'TopCurrencies',
  props: {
    coins: {}
  },
  components: {
    // VueCountdown: VueCountdown,
    // SelfBuildingSquareSpinner,
  },
  computed: {
    ...mapStores(useRaffleStore),
    spendersCoin() {
      let topSpenders = this.coins.find(c => c.kt === this.selectedCoin).spenders[0]
      // console.log(`${this.selectedCoin} Spenders`, topSpenders)
      return topSpenders
    },
  },
  methods: {
    formatAddress(pkh) {
      if (pkh === '') return 0
      var newWallets = JSON.parse(JSON.stringify(this.raffleStore.wallets))
      var filtered = newWallets.filter(element => element.wallet === pkh)
      if (filtered.length == 1) return filtered[0].domain
      return formatPkhString(pkh);
    }
  },
  data() {
    return {
      selectedCoin: "tezos",
      selectedSymbol: "$XTZ",
      optional: false,
      working: false,
    }
  },
  // beforeUnmount() {
  //   // cleanup event listeners
  //   this.$refs.tzRaffleDetailModalContainer.removeEventListener('click', this.stopPropagation)
  //   document.body.removeEventListener('click', this.closeModal)
  // },
  // async mounted() {
  //   // tzRaffle #6 tokenId 7, 2000 supply
  //   // this.balance = await this.raffleStore.getRaffleBalance(7) || 2000
  //   this.stats = await this.raffleStore.getStats()
  //   // attach listeners for handling clicks outside the card, while preventing propagation
  //   // of clicks in the cards
  //   this.$refs.tzRaffleDetailModalContainer.addEventListener('click', this.stopPropagation)
  //   document.body.addEventListener('click', this.closeModal)
  // },
}
</script>
  
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Amatic+SC");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

.list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.list::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.list::-webkit-scrollbar-thumb {
  background-color: #646464;
}

.container {
  width: 445px;
  /* height: 600px; */
  /* background-color: black; */
  margin: auto;
  font-family: "Amatic SC";
  margin: auto;
  padding: 1rem;
  border-radius: 5px;
  /* box-shadow: 7px 9px 7px #00000052; */
}

.container .leader {
  display: flex;
}

.container .crown {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.container .crown svg {
  width: 3rem;
}

.container .image {
  height: 58px;
  object-fit: cover;
  width: 58px;
  background-color: black;
  border-radius: 50%;
}

.container .topLeadersList {
  display: flex;
  position: relative;
  min-height: 120px;
  padding-top: 3rem;
}

.container .topLeadersList .image {
  border-radius: 50%;
  border: 3px solid #b159ffcc;
}

.container .topLeadersList .leader:nth-child(1) {
  color: black;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99
}

.container .topLeadersList .leader:nth-child(1):after {
  content: "1";
  width: 30px;
  height: 30px;
  background: #ffc500;
  border-radius: 50%;
  position: absolute;
  right: 0;
  font-family: sans-serif;
  text-align: center;
  line-height: 30px;
  font-weight: 700;
  box-shadow: 1px 1px 4px black;
}

.container .topLeadersList .leader:nth-child(1) .image {
  width: 130px;
  height: 130px;
}

.container .topLeadersList .leader:nth-child(1) .crown {
  top: -20%;
}

.container .topLeadersList .leader:nth-child(1) .crown svg {
  fill: #ffc500;
}

.container .topLeadersList .leader:nth-child(2) {
  color: black;
  position: absolute;
  left: 15%;
  transform: translateX(-15%);
  bottom: -20%;
  /* z-index: 98 */
}

.container .topLeadersList .leader:nth-child(2):after {
  content: "2";
  width: 30px;
  height: 30px;
  background: #d4d4d4;
  border-radius: 50%;
  position: absolute;
  left: 0;
  font-family: sans-serif;
  text-align: center;
  line-height: 30px;
  font-weight: 700;
  box-shadow: 1px 1px 4px black;
}

.container .topLeadersList .leader:nth-child(2) .image {
  width: 110px;
  height: 110px;
}

.container .topLeadersList .leader:nth-child(2) .crown {
  top: -25%;
}

.container .topLeadersList .leader:nth-child(2) .crown svg {
  fill: #d4d4d4;
}

.container .topLeadersList .leader:nth-child(3) {
  color: black;
  position: absolute;
  left: 85%;
  transform: translateX(-85%);
  bottom: -20%;
}

.container .topLeadersList .leader:nth-child(3):after {
  content: "3";
  width: 30px;
  height: 30px;
  background: #ab6528;
  border-radius: 50%;
  position: absolute;
  right: 0;
  font-family: sans-serif;
  text-align: center;
  line-height: 30px;
  font-weight: 700;
  box-shadow: 1px 1px 4px black;
}

.container .topLeadersList .leader:nth-child(3) .image {
  width: 110px;
  height: 110px;
}

.container .topLeadersList .leader:nth-child(3) .crown {
  top: -25%;
}

.container .topLeadersList .leader:nth-child(3) .crown svg {
  fill: #ab6528;
}

.container .leaderName {
  position: absolute;
  text-align: center;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
}

.container .player {
  background-color: #330b7775;
  display: grid;
  grid-template-columns: 0.4fr 3fr 1fr 0.5fr 1fr;
  align-items: center;
  min-height: 42px;
  text-align: center;
  padding-right: 0.4rem;
}

.container .player .image {
  width: 28px;
  height: 28px;
  border: 1.5px solid white;
}

.container .table {
  margin-right: 0.1rem;
  display: grid;
  font-size: 14px;
  grid-template-columns: 0.4fr 3fr 1fr 0.5fr 1fr;
  text-align: left;
  margin-bottom: 0px;
}

.container .table div:nth-child(2) {
  text-align: left;
  margin-left: 5px;
}

.container .table .image {
  width: 20px;
  height: 20px;
}

.container .playerslist {
  margin-top: 1rem;
  font-size: 16px;
  overflow: hidden;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
}

.container .playerslist .player:nth-child(odd) {
  background-color: #b159ffcc;
  align-items: center;
}

.container .user {
  display: flex;
  align-items: center;
  gap: 4px;
}

.container .list {
  height: 21rem;
  overflow-x: hidden;
}

.container .list-short {
  height: 9rem;
  overflow-x: hidden;
}

.container .spender {
  background-color: #330b7775;
  display: grid;
  grid-template-columns: 0.4fr 4fr 4fr;
  align-items: center;
  min-height: 42px;
  text-align: center;
  padding-right: 0.4rem;
}

.container .spender .image {
  width: 28px;
  height: 28px;
  border: 1.5px solid white;
}
</style>