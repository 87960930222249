<template>
  <div class="raffles-element">

    <div class="container">
      <div class="row mt-5 mb-5">
        <h5 class="font-main-light-italic">All Raffles</h5>

        <AllRafflesElement />

      </div>
    </div>
  </div>
</template>

<script>
// import NewRafflesElement from "@/components/NewRafflesElement.vue"
// import EndingRafflesElement from "@/components/EndingRafflesElement.vue"
// import PastRafflesElement from "@/components/PastRafflesElement.vue"
// import EnteredRafflesElement from "@/components/EnteredRafflesElement.vue"
// import CreatedRafflesElement from "@/components/CreatedRafflesElement.vue"
// import FreeRafflesElement from "@/components/FreeRafflesElement.vue"
import AllRafflesElement from "@/components/lab/AllRafflesElement.vue"

import { mapStores } from 'pinia'
import { useRaffleStore } from '@/stores/RaffleStore'

export default {
  name: 'RafflesElement',
  data() {
    return {
      tab: 'new'
    }
  },
  components: {
    // NewRafflesElement,
    // EndingRafflesElement,
    // PastRafflesElement,
    // EnteredRafflesElement,
    // CreatedRafflesElement,
    // FreeRafflesElement,
    AllRafflesElement,
  },
  computed: {
    ...mapStores(useRaffleStore),
  },
  methods: {
    switchTab(tab) {
      this.tab = tab
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
 