<template>
  <div class="card card-hover box-shadow main-card mt-3 mb-3">
    <div class="card-body text-center text-truncate pb-075">
      <div class="skeleton skeleton-text" style="  width: 100%;  height: 2rem;"></div>
      <div class="skeleton skeleton-text" style="  width: 100%;  height: 1rem;"></div>
      <div class="skeleton skeleton-text" style="  width: 100%;  height: 1rem;"></div>
    </div>
    <div class="wrapper ms-2 me-2">
      <div class="skeleton skeleton-text mb-0" style=" width: 100%;  height: 288px;"></div>
    </div>
    <ul class="list-group list-group-flush text-center font-main">
      <li class="list-group-item item-sold"><div class="skeleton skeleton-text" style="  width: 100%;  height: 1.5rem;"></div></li>
      <li class="list-group-item item-floor"><div class="skeleton skeleton-text mt-3" style="  width: 100%;  height: 1.5rem;"></div></li> 
      <li class="list-group-item item-enter">
        <div class="font-main-light-italic small mb-2"><div class="skeleton skeleton-text" style="  width: 100%;  height: 1.5rem;"></div></div>
        <div class="skeleton skeleton-text" style="  width: 100%;  height: 2.5rem;"></div>
      </li>   
    </ul>    
</div>
</template>

<script>
export default {
}
</script>

<style scoped>
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-text{
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>
