<template>
  <div class="FooterElement pt-5">
    <div class="container pt-5">
        <div class="row">  	
          <div class="col col-12 col-md-6  align-center">
            <img v-if="raffleStore.theme === 'pepe'" src="Bootswatch_ Cyborg_files/images/pepe-br.png" class="w-100">
            <img v-else src="Bootswatch_ Cyborg_files/images/tezosbr2.png" class="w-100">
          </div>
          <div class="col col-12 col-md-6 ">
            <p class="font-main-light mt-5 float-bottom">Made by
               <img src="Bootswatch_ Cyborg_files/images/tzraffleslogo-nobr.png" style="width:135px;"> &copy; 2023</p>
            <ul class="list-unstyled font-main">
              <li class="float-end"><a href="#top"><i class="bi bi-arrow-up fs-5"></i></a></li>
               <li class=""><a href="https://discord.gg/JPC2WND2PR"><i class="bi bi-discord fs-5" style="color:#7289DA;"></i></a>&nbsp;  tzRaffles on Discord</li>
              <li><a href="https://twitter.com/tzRaffles"><i class="bi bi-twitter fs-5"></i></a>&nbsp;  @tzRaffles ON Twitter</li>
            </ul>
          </div>          
        </div>
      </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useRaffleStore } from '@/stores/RaffleStore';

export default {
  name: 'FooterElement',
  props: {
    msg: String
  },
  computed: {
    ...mapStores(useRaffleStore),
  },
}
</script>